import React from "react";
import { useState, useEffect, useRef, lazy } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "@rainbow-me/rainbowkit/styles.css";
import {
  ConnectButton,
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  fetchBalance,
  fetchBlockNumber,
  fetchTransaction,
  watchAccount,
  readContract,
  getAccount,
  getNetwork,
  writeContract,
  watchNetwork,
  prepareWriteContract,
} from "@wagmi/core";
// import { alchemyProvider } from "@wagmi/core/providers/alchemy";
// import { infuraProvider } from "@wagmi/core/providers/infura";

// TESTNET
// import { bscTestnet, polygonMumbai, goerli, mainnet } from 'wagmi/chains';
// import { bscTestnet, polygonMumbai, sepolia } from "wagmi/chains";

// VIEM
import { createPublicClient, http } from "viem";
import { arbitrum, avalanche, bsc, fantom, polygon, base, celo, evmos, gnosis, mainnet, moonriver, optimism, polygonZkEvm, scroll } from "viem/chains";

// MAINNET
// import {
  // avalanche,
  // bsc,
  // fantom,
  // polygon,
  // mainnet,
  // evmos,
  // arbitrum,
  // gnosis,
  // optimism,
// } from "wagmi/chains";

import { publicProvider } from "wagmi/providers/public";
import { ethers } from "ethers";

import {
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  coinbaseWallet,
  zerionWallet,
} from "@rainbow-me/rainbowkit/wallets";

import TimeToken from "./contracts/TimeToken.json";
import EmployerContract from "./contracts/Employer.json";
import D2Token from "./contracts/TimeIsUp.json";
import Chainlink from "./contracts/Chainlink.json";
import WETH from "./contracts/WETH.json";
import TimeExchange from "./contracts/TimeExchange.json";
import Claimer from "./contracts/Claimer.json";
import BlockFromContract from "./contracts/BlockFromContract.json";
import SponsorContract from "./contracts/Sponsor.json";
import WorkerVault from "./contracts/WorkerVault.json";
import MinionCoordinator from "./contracts/MinionCoordinator.json";

import {
  Typography,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Unstable_Grid2 as Grid,
  CssBaseline,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
  Stack,
} from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import GitBookIcon from "./components/GitBookIcon";

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import arbitrumLogo from "./arbitrum.svg";
import avalancheLogo from "./avalanche.svg";
import gnosisLogo from "./gnosis.svg";
import dsrv2Logo from "./tup2.svg";
import timeLogo from "./time_token_logo.svg";
import ethereumLogo from "./ethereum.svg";
import evmosLogo from "./evmos.svg";
import polygonLogo from "./polygon.svg";
import bscLogo from "./bnb.svg";
import fantomLogo from "./fantom.svg";
import zkEVMLogo from "./polygon-hermez.svg";
import celoLogo from "./celo.svg";
import xDaiLogo from "./xdai_novo.svg";
import moonriverLogo from "./moonriver.svg";
import baseLogo from "./base.svg";
import scrollLogo from "./scroll.svg";
import optimismLogo from "./optimism.svg";

// import { createClient as createClientLayerZero } from "@layerzerolabs/scan-client";

// import Bridge from "./components/Bridge";
// import CommunityPool from "./components/CommunityPool";
// import Employer from "./components/Employer";
// import Info from "./components/Info";
// import MintD2 from "./components/MintD2";
// import Production from "./components/Production";
// import Sponsor from "./components/Sponsor";
// import Swap from "./components/Exchange";
const Info = lazy(() => import("./components/Info"));
const Production = lazy(() => import("./components/Production"));
const Swap = lazy(() => import("./components/Exchange"));
const CommunityPool = lazy(() => import("./components/CommunityPool"));
const MintD2 = lazy(() => import("./components/MintD2"));
const Bridge = lazy(() => import("./components/Bridge"));
const Employer = lazy(() => import("./components/Employer"));
const Sponsor = lazy(() => import("./components/Sponsor"));
const Worker = lazy(() => import("./components/Worker"));

const projectId = "c83cedb84fc3ad138c88f1fc743d07db";

// TESTNET
// const mumbai = {
//   ...polygonMumbai,
//   rpcUrls: {
//     ...polygonMumbai.rpcUrls,
//     default: {
//       http: "https://api.zan.top/node/v1/polygon/mumbai/public",
//       webSocket: "wss://polygon-mumbai-bor.publicnode.com",
//     },
//     public: {
//       http: "https://api.zan.top/node/v1/polygon/mumbai/public",
//       webSocket: "wss://polygon-mumbai-bor.publicnode.com",
//     },
//   },
// };
// const bscTestnetLogo = { ...bscTestnet, iconUrl: bscLogo };

// MAINNET
optimism.name = "Optimism";
// const bscNetLogo = { ...bsc, iconUrl: bscLogo };
bsc.iconUrl = bscLogo;
bsc.rpcUrls = {
  default: {
    http: [
      "https://public.stackup.sh/api/v1/node/bsc-mainnet",
      "https://bsc.blockpi.network/v1/rpc/public",
    ],
  },
  public: {
    http: [
      "https://public.stackup.sh/api/v1/node/bsc-mainnet",
      "https://bsc.blockpi.network/v1/rpc/public",
    ],
  },
};
// const ethereumNetLogo = { ...mainnet, iconUrl: ethereumLogo };
mainnet.iconUrl = ethereumLogo;
mainnet.rpcUrls = {
  default: {
    http: ["https://public.stackup.sh/api/v1/node/ethereum-mainnet"],
  },
  public: {
    http: ["https://public.stackup.sh/api/v1/node/ethereum-mainnet"],
  },
};
// const evmosNetLogo = {
//   ...evmos,
//   iconUrl: evmosLogo,
//   rpcUrls: {
//     public: { http: ["https://evmos-evm-rpc.publicnode.com"] },
//     default: { http: ["https://evmos-evm-rpc.publicnode.com"] },
//   },
// };
evmos.iconUrl = evmosLogo;
evmos.rpcUrls = {
  default: {
    http: ["https://evmos-evm-rpc.publicnode.com"],
  },
  public: {
    http: ["https://evmos-evm-rpc.publicnode.com"],
  },
};
// const fantomNetLogo = { ...fantom, iconUrl: fantomLogo };
fantom.iconUrl = fantomLogo;
fantom.rpcUrls = {
  default: {
    http: [
      "https://rpc.ankr.com/fantom", 
      "https://fantom.blockpi.network/v1/rpc/public",
    ],
  },
  public: {
    http: [
      "https://rpc.ankr.com/fantom", 
      "https://fantom.blockpi.network/v1/rpc/public",
    ],
  },
};
// const gnosisNetLogo = {
//   ...gnosis,
//   rpcUrls: {
//     public: { http: ["https://gnosis.drpc.org"] },
//     default: { http: ["https://gnosis.drpc.org"] },
//   },
//   iconUrl: gnosisLogo
// };
gnosis.iconUrl = gnosisLogo;
gnosis.rpcUrls = {
  default: {
    http: [
      "https://gnosis.drpc.org",
      "https://gnosis.blockpi.network/v1/rpc/public",
    ],
  },
  public: {
    http: [
      "https://gnosis.drpc.org",
      "https://gnosis.blockpi.network/v1/rpc/public",
    ],
  },
};

// TESTNET
// const net = "testnet";
// MAINNET
const net = "mainnet";

// MAINNET
// const polygonZkEvm = {
//   ...mainnet,
//   id: 1_101,
//   name: "Polygon zkEVM",
//   network: "zkevm",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Ethereum",
//     symbol: "ETH",
//   },
//   iconUrl: zkEVMLogo,
//   rpcUrls: {
//     public: { http: ["https://zkevm-rpc.com"] },
//     default: { http: ["https://zkevm-rpc.com"] },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Polygon zkEVM Explorer",
//       url: "https://zkevm.polygonscan.com",
//     },
//     default: {
//       name: "Polygon zkEVM Explorer",
//       url: "https://zkevm.polygonscan.com",
//     },
//   },
// };

polygonZkEvm.iconUrl = zkEVMLogo;

polygon.rpcUrls = {
  default: {
    http: [
      "https://1rpc.io/matic",
      "https://nameless-prettiest-hexagon.matic.quiknode.pro/f2a74f7af168c6709db85ca07d147a32cf301d56/",
      "https://polygon.rpc.blxrbdn.com",
      "https://polygon-pokt.nodies.app",
      "https://polygon-rpc.com",
    ],
    webSocket: [
      "wss://polygon-bor-rpc.publicnode.com",
      "wss://nameless-prettiest-hexagon.matic.quiknode.pro/f2a74f7af168c6709db85ca07d147a32cf301d56/",
    ],
  },
  public: {
    http: [
      "https://1rpc.io/matic",
      "https://nameless-prettiest-hexagon.matic.quiknode.pro/f2a74f7af168c6709db85ca07d147a32cf301d56/",
      "https://polygon.rpc.blxrbdn.com",
      "https://polygon-pokt.nodies.app",
      "https://polygon-rpc.com",
    ],
    webSocket: [
      "wss://nameless-prettiest-hexagon.matic.quiknode.pro/f2a74f7af168c6709db85ca07d147a32cf301d56/",
      "wss://polygon-bor-rpc.publicnode.com",
    ],
  },
};

// const polygonQuickNode = {
//   id: 137,
//   name: "Polygon",
//   network: "matic",
//   nativeCurrency: {
//     decimals: 18,
//     name: "MATIC",
//     symbol: "MATIC",
//   },
//   rpcUrls: {
//     default: {
//       http: ["https://polygon.llamarpc.com"],
//       webSocket: ["wss://polygon-bor-rpc.publicnode.com"],
//     },
//     public: {
//       http: ["https://polygon.llamarpc.com"],
//       webSocket: ["wss://polygon-bor-rpc.publicnode.com"],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: "PolygonScan",
//       url: "https://polygonscan.com",
//       apiUrl: "https://api.polygonscan.com/api"
//     }
//   },
//   contracts: {
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11",
//       blockCreated: 25770160
//     }
//   },
// };

// const base = {
//   ...mainnet,
//   id: 8453,
//   name: "Base",
//   network: "base",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Ethereum",
//     symbol: "ETH",
//   },
//   iconUrl: baseLogo,
//   rpcUrls: {
//     public: { http: ["https://base.publicnode.com"] },
//     default: { http: ["https://base.publicnode.com"] },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Base Explorer",
//       url: "https://basescan.org",
//     },
//     default: {
//       name: "Base Explorer",
//       url: "https://basescan.org",
//     },
//   },
// };

base.iconUrl = baseLogo;

// const scroll = {
//   ...mainnet,
//   id: 534352,
//   name: "Scroll",
//   network: "scroll",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Ethereum",
//     symbol: "ETH",
//   },
//   iconUrl: scrollLogo,
//   rpcUrls: {
//     public: {
//       http: ["https://rpc.scroll.io", "https://scroll.drpc.org"],
//     },
//     default: {
//       http: ["https://rpc.scroll.io", "https://scroll.drpc.org"],
//     },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Scroll Scan",
//       url: "https://scrollscan.com",
//     },
//     default: {
//       name: "Scroll Scan",
//       url: "https://scrollscan.com",
//     },
//   },
// };

scroll.iconUrl = scrollLogo;

// const celo = {
//   ...mainnet,
//   id: 42_220,
//   name: "Celo",
//   network: "celo",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Celo",
//     symbol: "CELO",
//   },
//   iconUrl: celoLogo,
//   rpcUrls: {
//     public: { http: ["https://forno.celo.org"] },
//     default: { http: ["https://forno.celo.org"] },
//   },
//   blockExplorers: {
//     etherscan: { name: "Celo Chain Explorer", url: "https://celoscan.io" },
//     default: { name: "Celo Chain Explorer", url: "https://celoscan.io" },
//   },
// };

celo.iconUrl = celoLogo;

// const moonriver = {
//   ...mainnet,
//   id: 1_285,
//   name: "Moonriver",
//   network: "moonriver",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Moonriver",
//     symbol: "MOVR",
//   },
//   iconUrl: moonriverLogo,
//   rpcUrls: {
//     public: { http: ["https://rpc.api.moonriver.moonbeam.network"] },
//     default: { http: ["https://rpc.api.moonriver.moonbeam.network"] },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Moonriver Chain Explorer",
//       url: "https://moonriver.moonscan.io",
//     },
//     default: {
//       name: "Moonriver Chain Explorer",
//       url: "https://moonriver.moonscan.io",
//     },
//   },
// };

moonriver.iconUrl = moonriverLogo;

const { chains, provider } = configureChains(
  // [mumbai, bscTestnetLogo, sepolia],
  // [
  // arbitrum,
  // avalanche,
  // base,
  // bscNetLogo,
  // localhost,
  // sepolia,
  // celo,
  // ethereumNetLogo,
  // evmosNetLogo,
  // fantomNetLogo,
  // gnosisNetLogo,
  // moonriver,
  // mumbai,
  // optimism,
  // polygon,
  // zkEVM,
  // scroll,
  // ],
  [
    arbitrum,
    avalanche,
    base,
    bsc,
    celo,
    mainnet,
    evmos,
    fantom,
    gnosis,
    moonriver,
    optimism,
    polygon,
    polygonZkEvm,
    scroll,
  ],
  [
    // alchemyProvider({apiKey: "H5LSh-49wDUhhSejLLFqXIpKenNer27x", priority: 1}), // Sepolia
    // infuraProvider({ apiKey: "3583650156c340d4a08c51b6e50b7117", priority: 2}), // Sepolia
    publicProvider({ priority: 1 }),
    // alchemyProvider({
    //   apiKey: "pKqcIGZ1X-KGQ64oytiernHFmmuYdi6f",
    //   priority: 1,
    // }),
    // alchemyProvider({
    //   apiKey: "X6cQe3ZB68kFDq1yr4B0CXQL_embUUPQ",
    //   priority: 2,
    // }),
    // jsonRpcProvider({
    //   priority: 5,
    //   rpc: () => ({
    //     // http: "https://crimson-serene-asphalt.matic.quiknode.pro/e8d3b9b9eaf91079c5db1c5bbfa43bd6c384993f/",
    //     webSocket: "wss://nameless-prettiest-hexagon.matic.quiknode.pro/f2a74f7af168c6709db85ca07d147a32cf301d56/",
    //   }),
    // }),
    // infuraProvider({ apiKey: "3583650156c340d4a08c51b6e50b7117", priority: 3 }),
    // infuraProvider({ apiKey: "2a0870c6860341dbb30a205fd6c7324f", priority: 4 }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet(
        { projectId, chains }
      ),
      zerionWallet({ projectId, chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
});

let theme = createTheme({
  typography: {
    h1: {
      color: "#2a7f9e",
      fontFamily: ["Righteous", "cursive"].join(","),
    },
    h6: {
      color: "#858535",
      fontFamily: ["Righteous", "cursive"].join(","),
    },
    span: {
      color: "#999999",
      fontSize: "0.65em",
    },
    span2: {
      color: "#999999",
      fontSize: "0.8em",
    },
    paragraph: {
      display: "flex",
      color: "#555555",
      fontSize: "0.8em",
    },
  },
  palette: {
    primary: {
      main: "#e6c800",
    },
    secondary: {
      main: "#858535",
    },
  },
});
theme = responsiveFontSizes(theme);

const App = () => {
  const GAS_LIMIT = 400_000;
  const GAS_LIMIT_D2 = 6_000_000;
  const GAS_LIMIT_D2_ARBITRUM = 8_000_000;
  const GAS_LIMIT_EMPLOYER = 900_000;
  const GAS_LIMIT_ARBITRUM = 7_000_000;
  const GAS_LIMIT_SWAP = 4_000_000;
  const TIMEOUT = 20_000;

  const TAB_SIZE = "18em";

  const STANDARD_ERROR_MESSAGE = `Maybe you need to check the gas amount, gas price or the value informed. Please check the transaction in block explorer for additional information`;

  const PAIR_ADDRESSES = {
    arbitrum: [42161, "0x008456487c8783ca3FbB3592f6dd6792e84a96B2"],
    base: [8453, "0x8694A84084626861fdc9f27D383e07F68B967206"],
    ethereum: [1, "0x273A143B6336F0D2c2434aEf5142D537d6181508"],
    polygon: [137, "0x20a7dEF0410d986AEEA1e0da09B62BC5517CD126"],
    bsc: [56, "0xD72761f0B984acA9E0bEdE60227C636b64D92DC1"],
    fantom: [250, "0xdC13D361dbd209fa35c4EB2Dcfad6731545009f9"],
    gnosis: [100, "0x065C2a61BD60Fef8FF2B0824Eca754bf23858129"],
    scroll: [534352, "0x2EE34944D396B8c46CFA6CdB3f036c9b341dCbE4"],
    moonriver: [1285, "0x321EB2DA5B8eD851304B44d9A11e5c04869a987f"],
    celo: [42220, "0xa65898acfaCEddC2537EfA44ACa6552b976D620B"],
    evmos: [9001, "0x098A7Cd87F3Cd3179310Cc92cb67c07C1d73D779"],
    zkevm: [1101, "0x99b2D076d9f70b44B9031790f04d91AbCBA6C44A"],
    bsc_testnet: [97, "0xb972f8605e26B9132613225ccdbC929139e0Be21"],
    mumbai: [80001, "0x9e9b7F4989644ecAfD26e55C5A3ffe3213180339"],
    sepolia: [11155111, "0xf3298806d8ab4d2bd9950847d89aa5f2671212f7"],
  }; // PAIR ADDRESS ON DEX

  const [publicClient, setPublicClient] = useState({});

  const [account, setAccount] = useState(undefined);
  const [approvedTo, setApprovedTo] = useState("Employer");
  const [balance, setBalance] = useState(undefined);
  const [balanceInTime, setBalanceInTime] = useState(undefined);
  const [block, setBlock] = useState(undefined);
  const [chosenToken, setChosenToken] = useState("TIME");
  const [claimButtonClicked, setClaimButtonClicked] = useState(false);
  const [claimButtonMintD2Clicked, setClaimButtonMintD2Clicked] =
    useState(false);
  const [claimType, setClaimType] = useState("community_pool");
  const [confirmClaim, setConfirmClaim] = useState(false);
  const [confirmSwap, setConfirmSwap] = useState(false);
  const [dexBalance, setDexBalance] = useState(undefined);
  const [dexBalanceTup, setDexBalanceTup] = useState(undefined);
  const [dexBalanceInD2, setDexBalanceInD2] = useState(undefined);
  const [dexBalanceInTime, setDexBalanceInTime] = useState(undefined);
  const [enabled, setEnabled] = useState(false);
  const [enabledMessage, setEnabledMessage] = useState(false);
  const [enabledWarning, setEnabledWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expectedAmount, setExpectedAmount] = useState(0);
  const [fee, setFee] = useState(undefined);
  const [feeInTime, setFeeInTime] = useState(undefined);
  const [flipped, setFlipped] = useState(false);
  const [hasEnoughBalanceDialog, setHasEnoughBalanceDialog] = useState(false);
  const [hasEnoughTimeApprovedDialog, setHasEnoughTimeApprovedDialog] = useState(false);
  const [hasEnoughTimeDialog, setHasEnoughTimeDialog] = useState(false);
  const [hasEnoughTimeMiningDialog, setHasEnoughTimeMiningDialog] =
    useState(false);
  const [hasEnoughToExchangeDialog, setHasEnoughToExchangeDialog] =
    useState(false);
  const [hasEnoughTupDialog, setHasEnoughTupDialog] = useState(false);
  const [tokenSymbolToSwap, setTokenSymbolToSwap] = useState(false);
  const [hasTimeNativeValueDialog, setHasTimeNativeValueDialog] =
    useState(false);
  const [informAmount, setInformAmount] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSwapSuccess, setIsSwapSuccess] = useState(false);
  const [isClaimSuccess, setIsClaimSuccess] = useState(false);
  const [isClaimFromWorkerSuccess, setIsClaimFromWorkerSuccess] = useState(false);
  const [isTxError, setIsTxError] = useState(false);
  const [logo, setLogo] = useState(ethereumLogo);
  const [logoAlt, setLogoAlt] = useState("Ethereum Logo");
  const [maxBalanceInTime, setMaxBalanceInTime] = useState(undefined);
  const [network, setNetwork] = useState(undefined);
  const [sendFromD2ButtonClicked, setSendFromD2ButtonClicked] = useState(false);
  const [sharedBalance, setSharedBalance] = useState(undefined);
  const [symbol, setSymbol] = useState("ETH");
  const [swapButtonClicked, setSwapButtonClicked] = useState(false);
  const [tokenButtonClicked, setTokenButtonClicked] = useState(false);
  const [totalSupply, setTotalSupply] = useState(undefined);
  const [useTimeToken, setUseTimeToken] = useState(false);
  const [withdrawableShare, setWithdrawableShare] = useState(undefined);

  // EMPLOYER VARIABLES
  const [action, setAction] = useState("approve");
  const [anticipateTime, setAnticipateTime] = useState(false);
  const [anticipationFee, setAnticipationFee] = useState(undefined);
  const [apy, setApy] = useState(undefined);
  // ----------------------------
  const [confirmCompound, setConfirmCompound] = useState(false);
  const [enabledAnticipationMessage, setEnabledAnticipationMessage] =
    useState(false);
  const [employerAnticipatedEarnings, setEmployerAnticipatedEarnings] =
    useState(undefined);
  const [employerEarnings, setEmployerEarnings] = useState(undefined);
  const [employerInvestment, setEmployerInvestment] = useState(undefined);
  const [employerLastBlock, setEmployerLastBlock] = useState(undefined);
  const [employerRemainingTime, setEmployerRemainingTime] = useState(undefined);
  const [hasApproved, setHasApproved] = useState(false);
  const [hasAnticipated, setHasAnticipated] = useState(false);
  const [hasCompounded, setHasCompounded] = useState(false);
  const [hasDeposited, setHasDeposited] = useState(false);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);
  const [informEmployerActionAmount, setInformEmployerActionAmount] =
    useState(false);
  const [isAnticipationEnabled, setIsAnticipationEnabled] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [textFieldTimeValueEmployer, setTextFieldTimeValueEmployer] =
    useState("");
  const [textFieldEthValueEmployer, setTextFieldEthValueEmployer] =
    useState("");
  const [totalEthDeposited, setTotalEthDeposited] = useState(undefined);
  const [totalTimeEmployed, setTotalTimeEmployed] = useState(undefined);
  const [withdrawnMethod, setWithdrawnMethod] = useState("deposit");
  // ----------------------------
  const [anticipateEarningsButtonClicked, setAnticipateEarningsButtonClicked] =
    useState(false);
  const [approveButtonClicked, setApproveButtonClicked] = useState(false);
  const [compoundButtonClicked, setCompoundButtonClicked] = useState(false);
  const [depositButtonClicked, setDepositButtonClicked] = useState(false);
  const [
    enabledAnticipationButtonClicked,
    setEnabledAnticipationButtonClicked,
  ] = useState(false);
  const [withdrawDepositButtonClicked, setWithdrawDepositButtonClicked] =
    useState(false);
  const [withdrawEarningsButtonClicked, setWithdrawEarningsButtonClicked] =
    useState(false);

  // SWAP VARIABLES
  const [addressFrom, setAddressFrom] = useState(ethers.constants.AddressZero);
  const [addressTo, setAddressTo] = useState(ethers.constants.AddressZero);
  const [fromValue, setFromValue] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");
  // const [textFieldTimeValue, setTextFieldTimeValue] = useState("");
  // const [textFieldEthValue, setTextFieldEthValue] = useState("");
  const [textFieldTimeValueEnabled, setTextFieldTimeValueEnabled] =
    useState(true);
  const [textFieldEthValueEnabled, setTextFieldEthValueEnabled] =
    useState(false);
  const [approveExchangeButtonClicked, setApproveExchangeButtonClicked] =
    useState(false);

  // MINT D2 VARIABLES
  const [approveMintD2ButtonClicked, setApproveMintD2ButtonClicked] =
    useState(false);
  const [balanceInD2, setBalanceInD2] = useState(undefined);
  const [useTimeTokenToMintD2, setUseTimeTokenToMintD2] = useState(false);
  const [d2TotalSupply, setD2TotalSupply] = useState(undefined);
  const [estimatedBridgeD2Fee, setEstimatedBridgeD2Fee] = useState(0);
  const [hasEnoughD2Dialog, setHasEnoughD2Dialog] = useState(false);
  const [informChainId, setInformChainId] = useState(false);
  const [isTokenApprovedTimeExchange, setIsTokenApprovedTimeExchange] =
    useState(false);
  const [isTimeApproveMintD2, setIsTimeApprovedMintD2] = useState(true);
  const [maxBalanceInD2, setMaxBalanceInD2] = useState(undefined);
  const [minNativeAmountToMintD2, setMinNativeAmountToMintD2] = useState(0);
  const [mintD2ButtonClicked, setMintD2ButtonClicked] = useState(false);
  const [publicReward, setPublicReward] = useState(undefined);
  const [publicRewardTime, setPublicRewardTime] = useState(undefined);
  const [textFieldEthValueMintD2, setTextFieldEthValueMintD2] = useState("");
  const [textFieldTimeValueMintD2, setTextFieldTimeValueMintD2] = useState("");
  const [textFieldD2AmountBridge, setTextFieldD2AmountBridge] = useState("");
  const [isMintD2Success, setIsMintD2Success] = useState(false);

  // BRIDGE D2 VARIABLES
  const [chainIdFrom, setChainIdFrom] = useState(0);
  const [chainIdTo, setChainIdTo] = useState(0);
  const [bridgeError, setBridgeError] = useState(false);
  const [isBridgeD2Success, setIsBridgeD2Success] = useState(false);
  const [feeProportion, setFeeProportion] = useState(1.5);
  const [sendFromDialog, setSendFromDialog] = useState(false);
  const [layerZeroScanLink, setLayerZeroScanLink] = useState(
    `https://${net == "testnet" ? net + "." : ""}layerzeroscan.com`
  );
  const [txHash, setTxHash] = useState(undefined);

  // INFO VARIABLES
  const [d2Price, setD2Price] = useState(0);
  const [timePrice, setTimePrice] = useState(0);
  const [nativePrice, setNativePrice] = useState(0);
  const [dexD2Balance, setDexD2Balance] = useState(0);
  const [externalDexBalance, setExternalDexBalance] = useState(0);
  const [externalDexD2Balance, setExternalD2Balance] = useState(0);

  // SPONSOR VARIABLES
  const [amountRemainingForPrize, setAmountRemainingForPrize] = useState(0);
  const [approveButtonClickedSponsor, setApproveButtonClickedSponsor] =
    useState(false);
  const [currentLeader, setCurrentLeader] = useState(
    ethers.constants.AddressZero
  );
  const [currentPrize, setCurrentPrize] = useState(0);
  const [enabledClaimPrizeButtonClicked, setEnabledClaimPrizeButtonClicked] =
    useState(false);
  const [extendButtonClickedSponsor, setExtendButtonClickedSponsor] =
    useState(false);
  const [hasExtended, setHasExtended] = useState(false);
  const [interactionPoints, setInteractionPoints] = useState(0);
  const [interactionPointsLeader, setInteractionPointsLeader] = useState(0);
  const [isApprovedToSponsor, setIsApprovedToSponsor] = useState(false);
  const [isParticipatingInSponsorship, setIsParticipatingInSponsorship] =
    useState(false);
  // const [nextPrize, setNextPrize] = useState(0);
  const [numberOfParticipants, setNumberOfParticipants] = useState(0);
  const [prizeToClaim, setPrizeToClaim] = useState(0);
  const [round, setRound] = useState(0);
  const [sponsorLastBlock, setSponsorLastBlock] = useState(0);
  const [sponsorRemainingTime, setSponsorRemainingTime] = useState(0);
  const [textFieldTimeValueSponsor, setTextFieldTimeValueSponsor] =
    useState("");
  const [valuePoints, setValuePoints] = useState(0);

  // GAMBIARRA DAS BRABAS!!!
  // const [loadBridge, setLoadBrige] = useState(false);

  // const [loadCommunityPool, setLoadCommunityPool] = useState(false);

  // const [loadEmployer, setLoadEmployer] = useState(false);
  // const [loadInfo, setLoadInfo] = useState(false);
  // const [loadMintD2, setLoadMintD2] = useState(false);
  // const [loadProduction, setLoadProduction] = useState(false);
  // const [loadSponsor, setLoadSponsor] = useState(false);
  // const [loadSwap, setLoadSwap] = useState(false);

  // useEffect(() => {
  // setLoadBrige(true);

  // setLoadCommunityPool(true);

  // setLoadEmployer(true);
  // setLoadInfo(true);
  // setLoadMintD2(true);
  // setLoadProduction(true);
  // setLoadSponsor(true);
  // setLoadSwap(true);
  // }, []);

  // WORKER VARIABLES
  const [approveButtonClickedWorker, setApproveButtonClickedWorker] =
    useState(false);
  const [availableTimeFromWorker, setAvailableTimeFromWorker] = useState(0);
  const [totalAvailableTimeFromWorker, setTotalAvailableTimeFromWorker] = useState(0);
  const [callToProductionButtonClicked, setCallToProductionButtonClicked] = useState(false);
  const [claimFromWorkerButtonClicked, setClaimFromWorkerButtonClicked] = useState(false);
  const [depositButtonClickedWorker, setDepositButtonClickedWorker] = useState(false);
  // const [useTimeFromWorker, setUseTimeFromWorker] = useState(false);
  const [useWorkerToMintTup, setUseWorkerToMintTup] = useState(false);
  const [balanceFromWorkerVault, setBalanceFromWorkerVault] = useState(0);
  const [isApprovedToWorker, setIsApprovedToWorker] = useState(false);
  const [isWithdrawFromWorkerEnabled, setIsWithdrawFromWorkerEnabled] = useState(false);
  const [maxTupLockedInWorker, setMaxTupLockedInWorker] = useState(0);
  const [numberOfMinions, setNumberOfMinions] = useState(0);
  const [reinvestTup, setReinvestTup] = useState(false);
  const [releaseBlock, setReleaseBlock] = useState(0);
  const [textFieldTupValueWorker, setTextFieldTupValueWorker] = useState("");
  const [totalTupFromWorker, setTotalTupFromWorker] = useState(0);
  const [tupLockedInWorker, setTupLockedInWorker] = useState(0);
  const [withdrawButtonClickedWorker, setWithdrawButtonClickedWorker] = useState(false);

  useEffect(() => {
    try {
      querySponsorInteractionPointsLeader();
    } catch (error) { }
  }, [currentLeader]);

  useEffect(() => {
    try {
      if (hasWorker()) {
        queryNativeFromTimeAmount(availableTimeFromWorker);
      }
    } catch (error) { }
  }, [availableTimeFromWorker]);

  useEffect(() => {
    try {
      if (hasWorker()) {
        queryTupLockedInWorkerVault();
      }
    } catch (error) { }
  }, [balanceFromWorkerVault]);

  const addressRef = useRef(getAccount().address);
  const [resetDate, setResetDate] = useState(0);
  useEffect(() => {
    if (!getAccount().address && addressRef.current) {
      setResetDate(new Date().getTime());
    }
    addressRef.current = getAccount().address;
  }, [getAccount().address]);

  useEffect(() => {
    updateAccountInformation();
    if (network) {
      try {
        setPublicClient(createPublicClient({
          batch: {
            multicall: true
          },
          chain: network?.chain,
          transport: http()
        }));
      } catch (error) { }
    }
  }, [network, getAccount().address]);

  const claimShare = async () => {
    setClaimButtonClicked(true);
    if (getNetwork()?.chain) {
      const params = {
        address: TimeToken.chain[getNetwork()?.chain.id].address,
        abi: TimeToken.abi,
        functionName: "withdrawShare",
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT,
        },
        account,
      };
      let gasLimit;
      publicClient.estimateContractGas(params)
        .then((response) => {
          gasLimit = response;
        })
        .catch((error) => {
          gasLimit = GAS_LIMIT;
        });
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setIsClaimSuccess(true);
            setClaimButtonClicked(false);
          })
          .catch((error) => {
            setErrorMessage(`Please check if you have any claimable share`);
            setIsTxError(true);
            setClaimButtonClicked(false);
          });
      } catch (error) {
        setErrorMessage(error.message);
        setIsTxError(true);
        setClaimButtonClicked(false);
      }
    }
  };

  const clear = () => {
    setApy(undefined);
    setEnabled(false);
    setEnabledWarning(false);
    setBlock(undefined);
    setDexBalance(undefined);
    setDexBalanceTup(undefined);
    setDexBalanceInD2(undefined);
    setDexBalanceInTime(undefined);
    setBalance(undefined);
    setBalanceInTime(undefined);
    setTotalSupply(undefined);
    setFee(undefined);
    setFeeInTime(undefined);
    setSharedBalance(undefined);
    setTextFieldValue("");
    setTextFieldTimeValueEmployer("");
    setTextFieldEthValueEmployer("");
    setWithdrawableShare(undefined);
    setSymbol("ETH");
    setLogo(ethereumLogo);
    setLogoAlt("Ethereum Logo");
    // TODO: check every buttons clicked and fields filled
    setCallToProductionButtonClicked(false);
    setTokenButtonClicked(false);
    setClaimButtonClicked(false);
    setSwapButtonClicked(false);
    setAnticipateEarningsButtonClicked(false);
    setApproveButtonClicked(false);
    setCompoundButtonClicked(false);
    setDepositButtonClicked(false);
    setEnabledAnticipationButtonClicked(false);
    setWithdrawDepositButtonClicked(false);
    setWithdrawEarningsButtonClicked(false);
    setExtendButtonClickedSponsor(false);
    setApproveButtonClickedSponsor(false);
    setEnabledClaimPrizeButtonClicked(false);
    setTextFieldTimeValueSponsor("");
  };

  const convert = (number) => ethers.utils.formatEther(number);

  const enable = async () => {
    setTokenButtonClicked(true);
    const network = await getNetwork();
    if (network.chain) {
      if (useTimeToken) {
        if (
          parseFloat(balanceInTime?.split(",").join("")).toFixed(6) >=
          parseFloat(feeInTime?.split(",").join("")).toFixed(6)
        ) {
          enableMiningWithTimeToken();
        } else {
          setHasEnoughTimeMiningDialog(true);
          setUseTimeToken(false);
        }
      } else {
        if (parseFloat(balance?.split(",").join("")).toFixed(6) >= parseFloat(fee?.split(",").join("")).toFixed(6)) {
          enableMining();
        } else {
          setTokenButtonClicked(false);
          setHasEnoughBalanceDialog(true);
        }
      }
    }
  };

  const enableMining = async () => {
    const params = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "enableMining",
      value: ethers.utils.parseEther(fee),
      overrides: {
        value: ethers.utils.parseEther(fee),
        gasLimit: getNetwork()?.chain.id == arbitrum.id ? GAS_LIMIT_ARBITRUM : GAS_LIMIT,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        value: ethers.utils.parseEther(fee),
        gasLimit: gas
      },
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setEnabledMessage(true);
          setTokenButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setTokenButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(error.message);
      setIsTxError(true);
      setTokenButtonClicked(false);
    }
  };

  const enableMiningWithTimeToken = async () => {
    const params = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "enableMiningWithTimeToken",
      overrides: {
        gasLimit: getNetwork()?.chain.id == arbitrum.id ? GAS_LIMIT_ARBITRUM : GAS_LIMIT,
      },
      account,
    };
    // const gas = await publicClient.estimateContractGas(params);
    // const config = await prepareWriteContract({
    //   ...params,
    //   overrides: {
    //     gasLimit: gas
    //   },
    // });
    let gasLimit;
    publicClient.estimateContractGas(params)
      .then((response) => {
        gasLimit = response;
      })
      .catch((error) => {
        gasLimit = GAS_LIMIT;
      });
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit
      }
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setEnabledMessage(true);
          setTokenButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setTokenButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(error.message);
      setIsTxError(true);
      setTokenButtonClicked(false);
    }
  };

  const handlerSwitchEnableTime = (event) => {
    setUseTimeToken(event.target.checked);
  };

  const handlerSwitchEnableTimeToMintD2 = (event) => {
    if (!event.target.checked) {
      if (useWorkerToMintTup)
        return;
    }
    setUseTimeTokenToMintD2(event.target.checked);
  };

  const handlerSwitchEnableReinvestTup = (event) => {
    setReinvestTup(event.target.checked);
  };

  const handlerSwitchEnableWorkerToMintTup = (event) => {
    setUseWorkerToMintTup(event.target.checked);
    if (!useTimeTokenToMintD2) {
      setUseTimeTokenToMintD2(event.target.checked);
    }
    if (event.target.checked) {
      setTextFieldTimeValueMintD2("");
      queryTotalAvailableTimeFromWorker();
      queryAvailableTimeFromWorker();
    } else {
      setTextFieldTimeValueMintD2("");
    }
  }

  // const handlerSwitchEnableTimeFromWorker = (event) => {
  //   if (!event.target.checked) {
  //     setTextFieldTimeValueMintD2("");
  //     setAvailableTimeFromWorker("");
  //   }
  //   setUseTimeFromWorker(event.target.checked);
  //   if (event.target.checked) {
  //     queryAvailableTimeFromWorker();
  //   }
  // };

  const mining = async () => {
    setTokenButtonClicked(true);
    const params = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "mining",
      overrides: {
        gasLimit: getNetwork()?.chain.id == arbitrum.id ? GAS_LIMIT_ARBITRUM : GAS_LIMIT,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit: gas
      }
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setIsSuccess(true);
          setTokenButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setTokenButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(error.message);
      setIsTxError(true);
      setTokenButtonClicked(false);
    }
  };

  const queryBalance = async () => {
    const balance = await fetchBalance({ address: addressRef.current });
    setBalance(
      parseFloat(convert(balance.value)).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryBalanceInTime = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "balanceOf",
      args: [addressRef.current],
    };
    setMaxBalanceInTime(convert(await readContract(config)));
    setBalanceInTime(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryDexBalance = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "poolBalance",
    };
    setDexBalance(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryDexBalanceTup = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "poolBalance",
    };
    setDexBalanceTup(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryDexBalanceInD2 = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "balanceOf",
      args: [D2Token.chain[getNetwork()?.chain.id].address],
    };
    setDexBalanceInD2(convert(await readContract(config)));
  };

  const queryDexBalanceInTime = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "balanceOf",
      args: [TimeToken.chain[getNetwork()?.chain.id].address],
    };
    setDexBalanceInTime(convert(await readContract(config)));
  };

  const queryEnabled = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "isMiningAllowed",
      args: [addressRef.current],
    };
    setEnabled(await readContract(config));
  };

  const queryFee = async () => {
    let config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "fee",
    };
    let f = await readContract(config);
    f = f.add(f.div(10));
    let feeValue = parseFloat(convert(f)).toLocaleString("en-us", {
      minimumFractionDigits: 6,
    });
    setFee(feeValue != 0 ? feeValue : parseFloat(0.000001).toFixed(6));
    config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "feeInTime",
    };
    setFeeInTime(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const querySharedBalance = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "sharedBalance",
    };
    setSharedBalance(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const querySwapPrice = async (tokenFrom, tokenTo, amount) => {
    if (getNetwork()?.chain) {
      amount = ethers.utils.parseEther(amount.split(",").join(""));
      const config = {
        address: TimeExchange.chain[getNetwork()?.chain.id].address,
        abi: TimeExchange.abi,
        functionName: "queryPrice",
        args: [tokenFrom, tokenTo, amount.toString()],
      };
      const rate = await readContract(config);
      let eA;
      if (
        tokenFrom == ethers.constants.AddressZero ||
        tokenTo == ethers.constants.AddressZero
      ) {
        eA = amount.mul(rate[0]).div(ethers.utils.parseUnits("10", 17));
      } else {
        eA = rate[0].mul(rate[1]).div(ethers.utils.parseUnits("10", 17));
      }
      setExpectedAmount(eA);
      setTextFieldValue(
        parseFloat(ethers.utils.formatEther(eA)).toLocaleString("en-us", {
          minimumFractionDigits: 6,
        })
      );
      setEnabledWarning(true);
    }
  };

  // const querySwapPriceNative = async (amountNative) => {
  //   if (getNetwork()?.chain) {
  //     amountNative = ethers.utils.parseEther(amountNative.split(",").join(""));
  //     const config = {
  //       address: TimeToken.chain[getNetwork()?.chain.id].address,
  //       abi: TimeToken.abi,
  //       functionName: "swapPriceNative",
  //       args: [amountNative.toString()],
  //     };
  //     const rate = await readContract(config);
  //     const eA = amountNative.mul(rate).div(ethers.utils.parseUnits("10", 17));
  //     setExpectedAmount(eA);
  //     setTextFieldTimeValue(
  //       parseFloat(ethers.utils.formatEther(eA)).toLocaleString("en-us", {
  //         minimumFractionDigits: 6,
  //       })
  //     );
  //     setEnabledWarning(true);
  //   }
  // };

  // const querySwapPriceTime = async (amountTime) => {
  //   if (getNetwork()?.chain) {
  //     amountTime = ethers.utils.parseEther(amountTime.split(",").join(""));
  //     const config = {
  //       address: TimeToken.chain[getNetwork()?.chain.id].address,
  //       abi: TimeToken.abi,
  //       functionName: "swapPriceTimeInverse",
  //       args: [amountTime.toString()],
  //     };
  //     const rate = await readContract(config);
  //     // const eA = amountTime.mul(ethers.utils.parseUnits('10', 35)).div(rate);
  //     const eA = amountTime.mul(rate).div(ethers.utils.parseUnits("10", 17));
  //     setExpectedAmount(eA);
  //     setTextFieldEthValue(
  //       parseFloat(ethers.utils.formatEther(eA)).toLocaleString("en-us", {
  //         minimumFractionDigits: 6,
  //       })
  //     );
  //     setEnabledWarning(true);
  //   }
  // };

  const queryTotalSupply = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "totalSupply",
    };
    setTotalSupply(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryWithdrawableShare = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "withdrawableShareBalance",
      args: [addressRef.current],
    };
    setWithdrawableShare(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const swap = async (amount) => {
    if (getNetwork()?.chain) {
      if (addressFrom == TimeToken.chain[getNetwork()?.chain?.id].address) {
        if (amount >= parseFloat(maxBalanceInTime.split(",").join("")))
          amount = maxBalanceInTime;
      } else if (
        addressFrom == D2Token.chain[getNetwork()?.chain?.id].address
      ) {
        if (amount >= parseFloat(maxBalanceInD2.split(",").join("")))
          amount = maxBalanceInD2;
      }
      const params = {
        address:
          getNetwork()?.chain.id == scroll.id ||
            getNetwork()?.chain.id == polygonZkEvm.id
            ? TimeExchange.chain[getNetwork()?.chain.id].address
            : SponsorContract.chain[getNetwork()?.chain.id].address,
        abi: SponsorContract.abi,
        functionName: "swap",
        args: [addressFrom, addressTo, ethers.utils.parseEther(amount)],
        value:
          addressFrom == ethers.constants.AddressZero
            ? ethers.utils.parseEther(amount)
            : 0,
        overrides: {
          value:
            addressFrom == ethers.constants.AddressZero
              ? ethers.utils.parseEther(amount)
              : 0,
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_SWAP,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          value:
            addressFrom == ethers.constants.AddressZero
              ? ethers.utils.parseEther(amount)
              : 0,
          gasLimit: gas
        },
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);

            setIsSwapSuccess(true);
            setTextFieldValue("");
            setEnabledWarning(false);
            setSwapButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(`We recommend you to adjust the amount`);
            setTextFieldValue("");
            setEnabledWarning(false);
            setIsTxError(true);
            setSwapButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(error.message);
        setIsTxError(true);
        setSwapButtonClicked(false);
      }
    }
  };

  const update = async () => {
    // setCallToProductionButtonClicked(false);
    // setTokenButtonClicked(false);
    // setClaimButtonClicked(false);
    // setClaimButtonMintD2Clicked(false);
    // setSwapButtonClicked(false);
    disableButtons();
    updateInformationBox();
    updateProductionBox();
    updateSwapBox();
    updateBridgeBox();
    updateMintD2Box();
    updateBlock();
    updateEmployerBox();
    updateSponsorBox();
    if (hasWorker()) {
      updateWorkerBox();
    }
  };

  const updateBlock = async () => {
    const network_id = getNetwork()?.chain?.id;
    if (
      network_id == arbitrum.id ||
      network_id == base.id ||
      network_id == optimism.id ||
      network_id == scroll.id ||
      network_id == polygonZkEvm.id
    ) {
      try {
        queryBlockNumber();
      } catch (error) { }
    } else {
      setBlock(await fetchBlockNumber());
    }
  };

  const queryBlockNumber = async () => {
    const config = {
      address: BlockFromContract.chain[getNetwork()?.chain.id].address,
      abi: BlockFromContract.abi,
      functionName: "getBlockNumber",
    };
    setBlock(parseInt(await readContract(config)));
  };

  const unwatch = watchAccount((account) => {
    try {
      setAccount(getAccount());
    } catch (error) {
      // console.log(error);
    }
  });

  const unwatchNetwork = watchNetwork(() => {
    try {
      setNetwork(getNetwork());
    } catch (error) {
      // console.log(error);
    }
  });

  const updateAccountInformation = async () => {
    try {
      const s = getNetwork()?.chain?.nativeCurrency?.symbol;
      setSymbol(s);
      switch (s) {
        case "AVAX":
          setLogo(avalancheLogo);
          setLogoAlt("Avax Logo");
          break;
        case "ETH":
          setLogo(ethereumLogo);
          setLogoAlt("Ethereum Logo");
          break;
        case "MATIC":
          setLogo(polygonLogo);
          setLogoAlt("Polygon Logo");
          break;
        case "BNB":
          setLogo(bscLogo);
          setLogoAlt("Binance Smart Chain Logo");
          break;
        case "FTM":
          setLogo(fantomLogo);
          setLogoAlt("Fantom Logo");
          break;
        case "tBNB":
          setLogo(bscLogo);
          setLogoAlt("Binance Smart Chain Logo");
          break;
        case "EVMOS":
          setLogo(evmosLogo);
          setLogoAlt("Evmos Logo");
          break;
        case "xDAI":
          setLogo(xDaiLogo);
          setLogoAlt("xDAI Logo");
          break;
        case "CELO":
          setLogo(celoLogo);
          setLogoAlt("Celo Logo");
          break;
        case "MOVR":
          setLogo(moonriverLogo);
          setLogoAlt("Moonriver Logo");
          break;
        default:
          setLogo(ethereumLogo);
          setLogoAlt("Ethereum Logo");
          break;
      }
      if (addressRef?.current === undefined) {
        clear();
      } else {
        update();
      }
    } catch (error) { }
  };

  const updateInformationBox = async () => {
    try {
      queryD2Price();
      if (
        getNetwork()?.chain?.id != optimism.id &&
        getNetwork()?.chain?.id != avalanche.id
      ) {
        queryExternalDexBalances();
      }
      queryDexD2Balance();
      queryDexBalanceInD2();
      queryNativePrice();
      queryTimePrice();
    } catch (error) { }
  };

  const updateEmployerBox = async () => {
    try {
      setAnticipateEarningsButtonClicked(false);
      setApproveButtonClicked(false);
      setCompoundButtonClicked(false);
      setDepositButtonClicked(false);
      setWithdrawDepositButtonClicked(false);
      setWithdrawEarningsButtonClicked(false);
      setTextFieldEthValueEmployer("");
      setTextFieldTimeValueEmployer("");
      setEnabledAnticipationButtonClicked(false);
      queryApy();
      queryAnticipationFee();
      queryEmployerEarnings();
      queryEmployerInvestment();
      updateBlock();
      queryLastBlock();
      queryRemainingTime();
      queryIsAnticipationEnabled();
      queryTotalEthDeposited();
      queryTotalTimeEmployed();
    } catch (error) { }
  };

  const updateMintD2Box = async () => {
    try {
      setTextFieldEthValueMintD2("");
      setTextFieldTimeValueMintD2("");
      setUseTimeTokenToMintD2(false);
      setUseWorkerToMintTup(false);
      setApproveMintD2ButtonClicked(false);
      setMintD2ButtonClicked(false);
      setReinvestTup(false);
      queryBalance();
      queryBalanceInD2();
      queryBalanceInTime();
      queryD2TotalSupply();
      queryPublicReward();
      if (hasWorker()) {
        queryAvailableTimeFromWorker();
      }
      updateSponsorBox();
    } catch (error) { }
  };

  const updateBridgeBox = async () => {
    try {
      setSendFromD2ButtonClicked(false);
      setTextFieldD2AmountBridge("");
      queryBalanceInD2();
    } catch (error) { }
  };

  const updateCommunityPoolBox = async () => {
    try {
      querySharedBalance();
      queryWithdrawableShare();
      queryBalanceInTime();
      queryTotalSupply();
    } catch (error) { }
  };

  const updateSwapBox = async () => {
    try {
      setTextFieldValue("");
      setEnabledWarning(false);
      queryBalance();
      queryBalanceInD2();
      queryBalanceInTime();
      queryDexBalance();
      queryDexBalanceTup();
      queryDexBalanceInD2();
      queryDexBalanceInTime();
      querySharedBalance();
      queryWithdrawableShare();
      updateSponsorBox();
    } catch (error) { }
  };

  const updateProductionBox = async () => {
    try {
      updateBlock();
      queryEnabled();
      queryFee();
      queryBalanceInTime();
      queryTotalSupply();
    } catch (error) { }
  };

  const updateSponsorBox = async () => {
    try {
      setExtendButtonClickedSponsor(false);
      setApproveButtonClickedSponsor(false);
      setEnabledClaimPrizeButtonClicked(false);
      setTextFieldTimeValueSponsor("");
      updateBlock();
      querySponsorLastBlock();
      querySponsorCurrentRound();
      querySponsorNumberOfParticipants();
      querySponsorCurrentLeader();
      querySponsorInteractionPoints(addressRef.current);
      querySponsorInteractionPointsLeader();
      querySponsorValuePoints();
      querySponsorRemainingTime();
      querySponsorAmountRemainingForPrize();
      querySponsorCurrentPrize();
      checkSponsorParticipation();
      querySponsorPrizeToClaim();
      queryBalanceInTime();
    } catch (error) { }
  };

  const updateWorkerBox = async () => {
    try {
      setTextFieldTupValueWorker("");
      queryBalanceInD2();
      queryBalanceFromWorkerVault();
      queryTotalTupFromWorker();
      updateBlock();
      queryBlockToUnlock();
      queryNumberOfActiveMinions();
      queryTotalAvailableTimeFromWorker();
      queryAvailableTimeFromWorker();
      queryTupLockedInWorkerVault();
      // queryConsumedShares();
      checkIfWithdrawIsEnabled();
    } catch (error) { }
  };

  // EMPLOYER SECTION
  const anticipate = async () => {
    setAnticipateEarningsButtonClicked(true);
    setAction("anticipate");
    if (
      textFieldTimeValueEmployer == "" ||
      parseFloat(textFieldTimeValueEmployer.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setAnticipateEarningsButtonClicked(false);
    } else {
      let amountTime = textFieldTimeValueEmployer.split(",").join("");
      if (
        parseFloat(amountTime) > parseFloat(balanceInTime.split(",").join(""))
      ) {
        setHasEnoughTimeDialog(true);
        setAnticipateEarningsButtonClicked(false);
        return;
      }
      if (
        parseFloat(amountTime) == parseFloat(balanceInTime.split(",").join(""))
      )
        amountTime = maxBalanceInTime;
      if (textFieldTimeValueEmployer == "") {
        amountTime = "0";
      }
      if (!isApproved) {
        setHasEnoughTimeApprovedDialog(true);
        setAnticipateEarningsButtonClicked(false);
        return;
      }
      const params = {
        address: EmployerContract.chain[getNetwork()?.chain.id].address,
        abi: EmployerContract.abi,
        functionName: "anticipate",
        args: [ethers.utils.parseEther(amountTime)],
        overrides: {
          gasLimit: getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasAnticipated(true);
            setAnticipateEarningsButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setAnticipateEarningsButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setAnticipateEarningsButtonClicked(false);
      }
    }
  };

  const approveTimeSpend = async () => {
    setChosenToken("TIME");
    setApprovedTo("Employer");
    setApproveButtonClicked(true);
    setAction("approve");
    if (
      textFieldTimeValueEmployer == "" ||
      parseFloat(textFieldTimeValueEmployer.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setApproveButtonClicked(false);
    } else {
      let amountTime = 0;
      if (
        parseFloat(textFieldTimeValueEmployer.split(",").join("")) ==
        parseFloat(maxBalanceInTime).toFixed(6)
      ) {
        amountTime = ethers.utils.parseEther(maxBalanceInTime);
      } else {
        amountTime = ethers.utils.parseEther(
          parseFloat(textFieldTimeValueEmployer.split(",").join("")).toString()
        );
      }
      const params = {
        address: TimeToken.chain[getNetwork()?.chain.id].address,
        abi: TimeToken.abi,
        functionName: "approve",
        args: [
          EmployerContract.chain[getNetwork()?.chain.id].address,
          amountTime,
        ],
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas
        },
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasApproved(true);
            setApproveButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setApproveButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setApproveButtonClicked(false);
      }
    }
  };

  const compound = async () => {
    setCompoundButtonClicked(true);
    let amountTime = textFieldTimeValueEmployer.split(",").join("");
    if (
      parseFloat(amountTime) > parseFloat(balanceInTime.split(",").join(""))
    ) {
      setHasEnoughTimeDialog(true);
      setCompoundButtonClicked(false);
      return;
    }
    if (amountTime == parseFloat(balanceInTime.split(",").join("")))
      amountTime = maxBalanceInTime;

    if (
      textFieldTimeValueEmployer == "" ||
      parseFloat(textFieldTimeValueEmployer.split(",").join("")) <= 0
    ) {
      amountTime = "0";
    }
    if (!isApproved && amountTime != 0) {
      if (!anticipateTime) {
        setHasEnoughTimeApprovedDialog(true);
        setCompoundButtonClicked(false);
        return;
      }
    }
    const params = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "compound",
      args: [ethers.utils.parseEther(amountTime), anticipateTime],
      overrides: {
        gasLimit: getNetwork()?.chain.id == arbitrum.id
          ? GAS_LIMIT_ARBITRUM
          : GAS_LIMIT_EMPLOYER,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit: gas
      }
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setHasCompounded(true);
          setCompoundButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setCompoundButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(STANDARD_ERROR_MESSAGE);
      setIsTxError(true);
      setCompoundButtonClicked(false);
    }
  };

  const deposit = async () => {
    setDepositButtonClicked(true);
    setAction("deposit");
    setApprovedTo("Employer");
    if (
      textFieldTimeValueEmployer == "" ||
      textFieldEthValueEmployer == "" ||
      parseFloat(textFieldTimeValueEmployer.split(",").join("")) <= 0 ||
      parseFloat(textFieldEthValueEmployer.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setDepositButtonClicked(false);
    } else {
      let amountTime = textFieldTimeValueEmployer.split(",").join("");
      if (
        parseFloat(amountTime) > parseFloat(balanceInTime.split(",").join(""))
      ) {
        setHasEnoughTimeDialog(true);
        setDepositButtonClicked(false);
        return;
      }
      if (amountTime == parseFloat(balanceInTime.split(",").join("")))
        amountTime = maxBalanceInTime;
      const params = {
        address: EmployerContract.chain[getNetwork()?.chain.id].address,
        abi: EmployerContract.abi,
        functionName: "deposit",
        args: [ethers.utils.parseEther(amountTime), anticipateTime],
        value: ethers.utils.parseEther(
          parseFloat(textFieldEthValueEmployer.split(",").join("")).toString()
        ),
        overrides: {
          value: ethers.utils.parseEther(
            parseFloat(textFieldEthValueEmployer.split(",").join("")).toString()
          ),
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          value: ethers.utils.parseEther(
            parseFloat(textFieldEthValueEmployer.split(",").join("")).toString()
          ),
          gasLimit: gas
        },
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasDeposited(true);
            setDepositButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setDepositButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setDepositButtonClicked(false);
      }
    }
  };

  const enableAnticipation = async () => {
    setEnabledAnticipationButtonClicked(true);
    if (parseFloat(balance?.split(",").join("")).toFixed(6) >= parseFloat(anticipationFee?.split(",").join("")).toFixed(6)) {
      const params = {
        address: EmployerContract.chain[getNetwork()?.chain.id].address,
        abi: EmployerContract.abi,
        functionName: "enableAnticipation",
        value: ethers.utils.parseEther(anticipationFee),
        overrides: {
          value: ethers.utils.parseEther(anticipationFee),
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          value: ethers.utils.parseEther(anticipationFee),
          gasLimit: gas,
        },
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setEnabledAnticipationMessage(true);
            setEnabledAnticipationButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setEnabledAnticipationButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setEnabledAnticipationButtonClicked(false);
      }
    } else {
      setEnabledAnticipationButtonClicked(false);
      setHasEnoughBalanceDialog(true);
    }
  };

  const handlerSwitchEnableTimeAnticipation = (event) => {
    setAnticipateTime(event.target.checked);
    if (event.target.checked)
      queryEmployerAnticipatedEarnings(textFieldTimeValueEmployer);
  };

  const queryEmployerAnticipatedEarnings = async (timeValue) => {
    let amountTime = timeValue.split(",").join("");
    if (timeValue == null || timeValue == "") amountTime = "0";
    if (amountTime == parseFloat(balanceInTime.split(",").join("")))
      amountTime = maxBalanceInTime;

    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "queryAnticipatedEarnings",
      args: [addressRef.current, ethers.utils.parseEther(amountTime)],
    };
    setEmployerAnticipatedEarnings(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      })
    );
  };

  const queryAnticipationFee = async () => {
    let config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "anticipationFee",
    };
    let f = await readContract(config);
    f = f.add(f.div(10));
    setAnticipationFee(
      parseFloat(convert(f)).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryApy = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "getCurrentROI",
    };
    const apy_ = await readContract(config);
    if (apy_.gt(ethers.utils.parseUnits("10000", 16))) setApy("> 10,000");
    else
      setApy(
        parseFloat(ethers.utils.formatUnits(apy_, 16)).toLocaleString("en-us", {
          maximumFractionDigits: 2,
        })
      );
  };

  const queryEmployerEarnings = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "queryEarnings",
      args: [addressRef.current],
    };
    setEmployerEarnings(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      })
    );
  };

  const queryEmployerInvestment = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "deposited",
      args: [addressRef.current],
    };
    setEmployerInvestment(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      })
    );
  };

  const queryIsAnticipationEnabled = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "anticipationEnabled",
      args: [addressRef.current],
    };
    setIsAnticipationEnabled(await readContract(config));
  };

  const queryIsTimeApproved = async (timeAmount) => {
    let amountTime = 0;
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "allowance",
      args: [
        addressRef.current,
        EmployerContract.chain[getNetwork()?.chain.id].address,
      ],
    };
    // if (timeAmount != "") {
    //   const allowance = await readContract(config);
    //   setIsApproved(
    //     allowance.gte(ethers.utils.parseEther(timeAmount.toString()))
    //   );
    // }
    if (timeAmount != "") {
      if (
        parseFloat(timeAmount.split(",").join("")) ==
        parseFloat(maxBalanceInTime).toFixed(6)
      ) {
        amountTime = ethers.utils.parseEther(maxBalanceInTime);
      } else {
        amountTime = ethers.utils.parseEther(
          parseFloat(timeAmount.split(",").join("")).toString()
        );
      }
      const allowance = await readContract(config);
      setIsApproved(allowance.gte(amountTime));
    }
  };

  const queryLastBlock = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "lastBlock",
      args: [addressRef.current],
    };
    setEmployerLastBlock(parseInt(await readContract(config)));
  };

  const queryRemainingTime = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "remainingTime",
      args: [addressRef.current],
    };
    const remainingTime = parseInt(
      ethers.utils.formatEther(await readContract(config))
    );
    setEmployerRemainingTime(remainingTime);
  };

  const queryTotalEthDeposited = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "currentDepositedNative",
    };
    setTotalEthDeposited(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      })
    );
  };

  const queryTotalTimeEmployed = async () => {
    const config = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "totalDepositedTime",
    };
    setTotalTimeEmployed(
      parseInt(convert(await readContract(config))).toLocaleString("en-us")
    );
  };

  const withdrawDeposit = async () => {
    setWithdrawDepositButtonClicked(true);
    setWithdrawnMethod("deposit");
    const params = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "withdrawDeposit",
      overrides: {
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_EMPLOYER,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit: gas
      },
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setHasWithdrawn(true);
          setWithdrawDepositButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setWithdrawDepositButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(STANDARD_ERROR_MESSAGE);
      setIsTxError(true);
      setWithdrawDepositButtonClicked(false);
    }
  };

  const withdrawEarnings = async () => {
    setWithdrawEarningsButtonClicked(true);
    setWithdrawnMethod("earnings");
    const params = {
      address: EmployerContract.chain[getNetwork()?.chain.id].address,
      abi: EmployerContract.abi,
      functionName: "withdrawEarnings",
      overrides: {
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_EMPLOYER,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit: gas,
      },
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setHasWithdrawn(true);
          setWithdrawEarningsButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setWithdrawEarningsButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(STANDARD_ERROR_MESSAGE);
      setIsTxError(true);
      setWithdrawEarningsButtonClicked(false);
    }
  };

  const approveTokenTimeExchange = async (token, textFieldValueFromToken) => {
    setChosenToken(
      token == TimeToken.chain[getNetwork()?.chain.id].address ? "TIME" : "TUP"
    );
    setApprovedTo("Exchange");
    setApproveExchangeButtonClicked(true);
    setAction("approve");
    if (
      textFieldValueFromToken == "" ||
      parseFloat(textFieldValueFromToken.split(",").join("")) <= 0
    ) {
      setApproveExchangeButtonClicked(false);
    } else {
      // Fix the problem with maximum amount selection
      const maxBalance =
        token == TimeToken.chain[getNetwork()?.chain.id].address
          ? maxBalanceInTime
          : maxBalanceInD2;
      let checkedAmount = 0;
      if (
        parseFloat(textFieldValueFromToken.split(",").join("")) ==
        parseFloat(maxBalance).toFixed(6)
      ) {
        checkedAmount = ethers.utils.parseEther(maxBalance);
      } else {
        checkedAmount = ethers.utils.parseEther(
          parseFloat(textFieldValueFromToken.split(",").join("")).toString()
        );
      }
      const tokenContract =
        token == TimeToken.chain[getNetwork()?.chain.id].address
          ? TimeToken
          : D2Token;
      const params = {
        address: token,
        abi: tokenContract.abi,
        functionName: "approve",
        args: [
          getNetwork()?.chain.id == scroll.id ||
            getNetwork()?.chain.id == polygonZkEvm.id
            ? TimeExchange.chain[getNetwork()?.chain.id].address
            : SponsorContract.chain[getNetwork()?.chain.id].address,
          checkedAmount,
        ],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_D2_ARBITRUM
              : GAS_LIMIT_D2,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas,
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            updateSwapBox();
            clearTimeout(clTimeout);
            setHasApproved(true);
            setIsTokenApprovedTimeExchange(true);
            setApproveExchangeButtonClicked(false);
          })
          .catch((error) => {
            updateSwapBox();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setApproveExchangeButtonClicked(false);
          });
      } catch (error) {
        updateSwapBox();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setApproveExchangeButtonClicked(false);
      }
    }
  };

  // D2 SECTION
  const isL2 = () => {
    return (
      getNetwork()?.chain?.id == arbitrum.id ||
      getNetwork()?.chain?.id == base.id ||
      getNetwork()?.chain?.id == avalanche.id ||
      getNetwork()?.chain?.id == optimism.id ||
      getNetwork()?.chain?.id == scroll.id ||
      getNetwork()?.chain?.id == polygonZkEvm.id
      // getNetwork()?.chain?.id == sepolia.id
    );
  };

  const approveTimeSpendMintD2 = async () => {
    setChosenToken("TIME");
    setApprovedTo("TUP Token");
    setApproveMintD2ButtonClicked(true);
    setAction("approve");
    if (
      textFieldTimeValueMintD2 == "" ||
      parseFloat(textFieldTimeValueMintD2.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setApproveMintD2ButtonClicked(false);
    } else {
      // Fix the problem with maximum TIME selection
      let amountTime = 0;
      if (
        parseFloat(textFieldTimeValueMintD2.split(",").join("")) ==
        parseFloat(maxBalanceInTime).toFixed(6)
      ) {
        amountTime = ethers.utils.parseEther(maxBalanceInTime);
      } else {
        amountTime = ethers.utils.parseEther(
          parseFloat(textFieldTimeValueMintD2.split(",").join("")).toString()
        );
      }
      const params = {
        address: TimeToken.chain[getNetwork()?.chain.id].address,
        abi: TimeToken.abi,
        functionName: "approve",
        args: [
          getNetwork()?.chain.id == scroll.id ||
            getNetwork()?.chain.id == polygonZkEvm.id
            ? D2Token.chain[getNetwork()?.chain.id].address
            : SponsorContract.chain[getNetwork()?.chain.id].address,
          amountTime,
        ],
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas,
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasApproved(true);
            setApproveMintD2ButtonClicked(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setApproveMintD2ButtonClicked(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setApproveMintD2ButtonClicked(false);
      }
    }
  };

  const claimReward = async () => {
    setClaimButtonMintD2Clicked(true);
    // const network_id = getNetwork()?.chain?.id;
    const params = {
      address:
        isL2()
          ? D2Token.chain[getNetwork()?.chain.id].address
          : Claimer.chain[getNetwork()?.chain.id].address,
      abi:
        isL2()
          ? D2Token.abi
          : Claimer.abi,
      functionName:
        isL2()
          ? "splitSharesWithReward"
          : "claim",
      args:
        isL2()
          ? []
          : [addressRef.current],
      overrides: {
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_D2_ARBITRUM
            : GAS_LIMIT_D2,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit: gas
      },
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setIsClaimSuccess(true);
          setClaimButtonMintD2Clicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setClaimButtonMintD2Clicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(error.message);
      setIsTxError(true);
      setClaimButtonMintD2Clicked(false);
    }
  };

  const willMintD2 = async () => {
    if (parseFloat(textFieldEthValueMintD2.split(",").join("")) > balance) {
      setHasEnoughBalanceDialog(true);
      return;
    }
    if (textFieldEthValueMintD2 === "") {
      setMintD2ButtonClicked(false);
      setAction("mint");
      setInformEmployerActionAmount(true);
      return;
    }
    setMintD2ButtonClicked(true);
    const network = await getNetwork();
    if (network.chain) {
      if (useTimeTokenToMintD2) {
        if (!useWorkerToMintTup) {
          if (
            parseFloat(textFieldEthValueMintD2.split(",").join("")) <
            minNativeAmountToMintD2
          ) {
            setHasTimeNativeValueDialog(true);
            return;
          } else {
            // if (parseFloat(textFieldTimeValueMintD2.split(",").join("")) > maxBalanceInTime) {
            //   setHasEnoughTimeDialog(true);
            //   setMintD2ButtonClicked(false);
            //   return;
            // }
            // mintD2(textFieldTimeValueMintD2 == "" ? 0 : textFieldTimeValueMintD2);
          }
        }
        if (parseFloat(textFieldTimeValueMintD2.split(",").join("")) > maxBalanceInTime) {
          setHasEnoughTimeDialog(true);
          setMintD2ButtonClicked(false);
          return;
        }
        mintD2(textFieldTimeValueMintD2 == "" ? 0 : textFieldTimeValueMintD2);
      } else {
        mintD2(0);
      }
    } else {
      setMintD2ButtonClicked(false);
    }
  };

  const mintD2 = async (timeAmount) => {
    setMintD2ButtonClicked(true);
    let amountTime = 0;
    if (timeAmount != 0) {
      if (!useWorkerToMintTup) {
        if (
          parseFloat(timeAmount.split(",").join("")) ==
          parseFloat(maxBalanceInTime).toFixed(6)
        ) {
          amountTime = ethers.utils.parseEther(maxBalanceInTime);
        } else {
          if (amountTime > maxBalanceInTime) {
            setHasEnoughTimeDialog(true);
            setMintD2ButtonClicked(false);
            return;
          }
          amountTime = ethers.utils.parseEther(
            parseFloat(timeAmount.split(",").join("")).toString()
          );
        }
      }
    }
    const params = {
      address: useWorkerToMintTup ? WorkerVault.chain[getNetwork()?.chain.id].address :
        getNetwork()?.chain.id == scroll.id || getNetwork()?.chain.id == polygonZkEvm.id
          ? D2Token.chain[getNetwork()?.chain.id].address
          : SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: useWorkerToMintTup ? WorkerVault.abi :
        getNetwork()?.chain.id == scroll.id || getNetwork()?.chain.id == polygonZkEvm.id
          ? D2Token.abi
          : SponsorContract.abi,
      functionName: useWorkerToMintTup ? balanceFromWorkerVault > 0 ? "mintTupAsDepositant" : "mintTupAsThirdParty" : "mint",
      args: useWorkerToMintTup ? balanceFromWorkerVault > 0 ? [reinvestTup] : [addressRef.current, reinvestTup] : [amountTime],
      value: ethers.utils.parseEther(
        parseFloat(textFieldEthValueMintD2.split(",").join("")).toString()
      ),
      overrides: {
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id ||
            getNetwork()?.chain.id == optimism.id ||
            getNetwork()?.chain.id == scroll.id
            ? GAS_LIMIT_D2_ARBITRUM
            : GAS_LIMIT_D2,
      },
      account,
    };
    // const gas = await publicClient.estimateContractGas(params);
    // const config = await prepareWriteContract({
    //   ...params,
    //   overrides: {
    //     value: ethers.utils.parseEther(
    //       parseFloat(textFieldEthValueMintD2.split(",").join("")).toString()
    //     ),
    //     gasLimit: gas
    //   }
    // });
    let gasLimit;
    publicClient.estimateContractGas(params)
      .then((response) => {
        gasLimit = response;
      })
      .catch((error) => {
        gasLimit = GAS_LIMIT_D2;
      });
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        value: ethers.utils.parseEther(
          parseFloat(textFieldEthValueMintD2.split(",").join("")).toString()
        ),
        gasLimit
      }
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setIsMintD2Success(true);
          setMintD2ButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setMintD2ButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(error.message);
      setIsTxError(true);
      setMintD2ButtonClicked(false);
    }
  };

  const queryBalanceInD2 = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "balanceOf",
      args: [addressRef.current],
    };
    const value = convert(await readContract(config));
    setMaxBalanceInD2(value);
    setBalanceInD2(
      parseFloat(value).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryD2TotalSupply = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "totalSupply",
    };
    setD2TotalSupply(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryNativeFromTimeAmount = async (timeAmount) => {
    if (getNetwork()?.chain) {
      timeAmount = ethers.utils.parseEther(timeAmount.split(",").join(""));
      const config = {
        address: D2Token.chain[getNetwork()?.chain.id].address,
        abi: D2Token.abi,
        functionName: "queryNativeFromTimeAmount",
        args: [timeAmount.toString()],
      };
      const minAmount = parseFloat(
        convert(await readContract(config))
      ).toLocaleString("en-us", { minimumFractionDigits: 6 });
      setMinNativeAmountToMintD2(minAmount);
    }
  };

  const queryPublicReward = async () => {
    // const network_id = getNetwork()?.chain?.id;
    const config = {
      address:
        isL2()
          ? D2Token.chain[getNetwork()?.chain.id].address
          : Claimer.chain[getNetwork()?.chain.id].address,
      abi:
        isL2()
          ? D2Token.abi
          : Claimer.abi,
      functionName:
        isL2()
          ? "queryPublicReward"
          : "queryPublicRewardEstimate",
    };
    const response = await readContract(config);
    if (isL2()) {
      setPublicReward(
        Intl.NumberFormat("en-us", {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6,
        }).format(convert(response))
      );
    } else {
      setPublicReward(
        Intl.NumberFormat("en-us", {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6,
        }).format(convert(response[0]))
      );
      setPublicRewardTime(
        Intl.NumberFormat("en-us").format(convert(response[1]))
      );
    }
  };

  const queryIsTimeApprovedMintD2 = async (timeAmount) => {
    let amountTime = 0;
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "allowance",
      args: [
        addressRef.current,
        getNetwork()?.chain.id == scroll.id || getNetwork()?.chain.id == polygonZkEvm.id
          ? D2Token.chain[getNetwork()?.chain.id].address
          : SponsorContract.chain[getNetwork()?.chain.id].address,
      ],
    };
    if (timeAmount != "") {
      if (
        parseFloat(timeAmount.split(",").join("")) ==
        parseFloat(maxBalanceInTime).toFixed(6)
      ) {
        amountTime = ethers.utils.parseEther(maxBalanceInTime);
      } else {
        amountTime = ethers.utils.parseEther(
          parseFloat(timeAmount.split(",").join("")).toString()
        );
      }
      const allowance = await readContract(config);
      // setIsTimeApprovedMintD2(allowance.gte(ethers.utils.parseEther(timeAmount.toString())));
      setIsTimeApprovedMintD2(allowance.gte(amountTime));
    } else {
      setIsTimeApprovedMintD2(true);
    }
  };

  const queryIsTokenApprovedTimeExchange = async (token, amount) => {
    if (token == ethers.constants.AddressZero) {
      setIsTokenApprovedTimeExchange(true);
      return;
    }
    let checkedAmount = 0;
    const tokenContract =
      token == TimeToken.chain[getNetwork()?.chain.id].address
        ? TimeToken
        : D2Token;
    const config = {
      address: token,
      abi: tokenContract.abi,
      functionName: "allowance",
      args: [
        addressRef.current,
        getNetwork()?.chain.id == scroll.id || getNetwork()?.chain.id == polygonZkEvm.id
          ? TimeExchange.chain[getNetwork()?.chain.id].address
          : SponsorContract.chain[getNetwork()?.chain.id].address,
      ],
    };
    if (amount != "") {
      const maxBalance =
        token == TimeToken.chain[getNetwork()?.chain.id].address
          ? maxBalanceInTime
          : maxBalanceInD2;
      if (
        parseFloat(amount.split(",").join("")) ==
        parseFloat(maxBalance).toFixed(6)
      ) {
        checkedAmount = ethers.utils.parseEther(maxBalance);
      } else {
        checkedAmount = ethers.utils.parseEther(
          parseFloat(amount.split(",").join("")).toString()
        );
      }
      const allowance = await readContract(config);
      setIsTokenApprovedTimeExchange(allowance.gte(checkedAmount));
    } else {
      setIsTokenApprovedTimeExchange(true);
    }
  };

  const estimateBridgeD2Fee = async () => {
    let amount;
    setBridgeError(false);
    if (
      textFieldD2AmountBridge == "" ||
      parseFloat(textFieldD2AmountBridge.split(",").join("")) <= 0
    ) {
      return;
    } else {
      amount = textFieldD2AmountBridge.split(",").join("");
      if (parseFloat(amount) > parseFloat(balanceInD2.split(",").join(""))) {
        return;
      }
    }
    if (chainIdFrom == chainIdTo || chainIdTo <= 0) {
      return;
    }
    amount = textFieldD2AmountBridge.split(",").join("");
    const args = [
      chainIdTo,
      ethers.utils.solidityPack(["address"], [addressRef.current]),
      ethers.utils.parseEther(amount),
      false,
      ethers.utils.solidityPack(
        ["uint16", "uint256"],
        [
          1,
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_D2,
        ]
      ),
    ];
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "estimateSendFee",
      args: args,
    };
    // setEstimatedBridgeD2Fee(
    //   (
    //     parseFloat(convert((await readContract(config))[0])) * 1.5
    //   ).toLocaleString("en-us", { minimumFractionDigits: 6 })
    // );
    readContract(config)
      .then((response) => {
        setEstimatedBridgeD2Fee(
          (parseFloat(convert(response[0])) * feeProportion).toLocaleString(
            "en-us",
            {
              minimumFractionDigits: 6,
            }
          )
        );
      })
      .catch((error) => {
        setBridgeError(true);
      });
  };

  const performBridgeD2 = async () => {
    let amountD2 = textFieldD2AmountBridge.split(",").join("");
    if (amountD2 == parseFloat(balanceInD2.split(",").join("")))
      amountD2 = maxBalanceInD2;

    const params = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "sendFrom",
      args: [
        addressRef.current,
        chainIdTo,
        ethers.utils.solidityPack(["address"], [addressRef.current]),
        ethers.utils.parseEther(amountD2),
        addressRef.current,
        ethers.constants.AddressZero,
        ethers.utils.solidityPack(
          ["uint16", "uint256"],
          [
            1,
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_D2_ARBITRUM,
          ]
        ),
      ],
      value: ethers.utils.parseEther(estimatedBridgeD2Fee),
      overrides: {
        value: ethers.utils.parseEther(estimatedBridgeD2Fee),
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_D2_ARBITRUM,
      },
      account,
    };
    const gas = await publicClient.estimateContractGas(params);
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        value: ethers.utils.parseEther(estimatedBridgeD2Fee),
        gasLimit: gas
      },
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          updateBridgeBox();
          clearTimeout(clTimeout);
          setSendFromD2ButtonClicked(false);
          setTxHash(
            data.hash?.substring(0, 7) +
            "......" +
            data.hash?.substring(data.hash?.length - 5, data.hash?.length)
          );
          setLayerZeroScanLink(layerZeroScanLink + "/tx/" + data.hash);
          setIsBridgeD2Success(true);
        })
        .catch((error) => {
          updateBridgeBox();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setSendFromD2ButtonClicked(false);
          setIsBridgeD2Success(false);
        });
    } catch (error) {
      updateBridgeBox();
      setErrorMessage(STANDARD_ERROR_MESSAGE);
      setIsTxError(true);
      setSendFromD2ButtonClicked(false);
      setIsBridgeD2Success(false);
    }
  };

  // PROTOCOL INFO SECTION
  const queryD2Price = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "queryPriceNative",
      args: [ethers.utils.parseEther("1")],
    };
    setD2Price(
      parseFloat(ethers.utils.formatEther(await readContract(config)))
    );
  };

  const queryTimePrice = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "swapPriceTimeInverse",
      args: [ethers.utils.parseEther("1")],
    };
    setTimePrice(
      parseFloat(ethers.utils.formatEther(await readContract(config)))
    );
  };

  const queryNativePrice = async () => {
    const config = {
      address: Chainlink.chain[getNetwork()?.chain.id].address,
      abi: Chainlink.abi,
      functionName: "latestRoundData",
    };
    if (Chainlink.chain[getNetwork()?.chain.id].address != "0") {
      const price = await readContract(config);
      setNativePrice(parseFloat(ethers.utils.formatUnits(price[1], 8)));
    } else {
      setNativePrice(undefined);
    }
  };

  const queryDexD2Balance = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "poolBalance",
    };
    setDexD2Balance(
      parseFloat(ethers.utils.formatEther(await readContract(config)))
    );
  };

  const queryExternalDexBalances = async () => {
    let args;
    switch (parseInt(getNetwork()?.chain?.id)) {
      case 1:
        args = PAIR_ADDRESSES.ethereum[1];
        break;
      case 42161:
        args = PAIR_ADDRESSES.arbitrum[1];
        break;
      case 137:
        args = PAIR_ADDRESSES.polygon[1];
        break;
      case 56:
        args = PAIR_ADDRESSES.bsc[1];
        break;
      case 250:
        args = PAIR_ADDRESSES.fantom[1];
        break;
      case 100:
        args = PAIR_ADDRESSES.gnosis[1];
        break;
      case 1285:
        args = PAIR_ADDRESSES.moonriver[1];
        break;
      case 42220:
        args = PAIR_ADDRESSES.celo[1];
        break;
      case 9001:
        args = PAIR_ADDRESSES.evmos[1];
        break;
      case 1101:
        args = PAIR_ADDRESSES.zkevm[1];
        break;
      case 97:
        args = PAIR_ADDRESSES.bsc_testnet[1];
        break;
      case 80001:
        args = PAIR_ADDRESSES.mumbai[1];
        break;
      case 11155111:
        args = PAIR_ADDRESSES.sepolia[1];
        break;
      case 8453:
        args = PAIR_ADDRESSES.base[1];
        break;
      case 534352:
        args = PAIR_ADDRESSES.scroll[1];
        break;
      default:
        return;
    }
    let config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "balanceOf",
      args: [args],
    };
    setExternalD2Balance(convert(await readContract(config)));
    config = {
      address: WETH.chain[getNetwork()?.chain.id].address,
      abi: WETH.abi,
      functionName: "balanceOf",
      args: [args],
    };
    setExternalDexBalance(convert(await readContract(config)));
  };

  // SPONSOR SECTION
  const querySponsorIsTimeApproved = async (timeAmount) => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "allowance",
      args: [
        addressRef.current,
        SponsorContract.chain[getNetwork()?.chain.id].address,
      ],
    };
    if (timeAmount != "") {
      const allowance = await readContract(config);
      setIsApprovedToSponsor(
        allowance.gte(ethers.utils.parseEther(timeAmount.toString()))
      );
    }
  };

  const querySponsorCurrentRound = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "round",
    };
    setRound(parseInt(await readContract(config)));
  };

  const querySponsorNumberOfParticipants = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "numberOfParticipants",
    };
    setNumberOfParticipants(parseInt(await readContract(config)));
  };

  const querySponsorCurrentLeader = async () => {
    const config = {
      address: SponsorContract?.chain[getNetwork()?.chain?.id]?.address,
      abi: SponsorContract?.abi,
      functionName: "currentLeader",
    };
    setCurrentLeader(await readContract(config));
  };

  const querySponsorInteractionPointsLeader = async () => {
    if (currentLeader != ethers.constants.AddressZero) {
      const config = {
        address: SponsorContract?.chain[getNetwork()?.chain?.id]?.address,
        abi: SponsorContract?.abi,
        functionName: "queryInteractionPoints",
        args: [currentLeader],
      };
      setInteractionPointsLeader(parseInt(await readContract(config)));
    }
  };

  const querySponsorInteractionPoints = async (participant) => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "queryInteractionPoints",
      args: [participant],
    };
    setInteractionPoints(parseInt(await readContract(config)));
  };

  const querySponsorValuePoints = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "queryValuePoints",
      args: [addressRef.current],
    };
    setValuePoints(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const querySponsorRemainingTime = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "remainingTime",
      args: [addressRef.current],
    };
    setSponsorRemainingTime(parseInt(await readContract(config)));
  };

  const querySponsorAmountRemainingForPrize = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "queryAmountRemainingForPrize",
    };
    setAmountRemainingForPrize(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const querySponsorCurrentPrize = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "queryCurrentTotalPrize",
    };
    setCurrentPrize(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const checkSponsorParticipation = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "checkParticipation",
      args: [addressRef.current],
    };
    setIsParticipatingInSponsorship(await readContract(config));
  };

  const querySponsorLastBlock = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "lastBlock",
      args: [addressRef.current],
    };
    setSponsorLastBlock(parseInt(await readContract(config)));
  };

  const querySponsorPrizeToClaim = async () => {
    const config = {
      address: SponsorContract.chain[getNetwork()?.chain.id].address,
      abi: SponsorContract.abi,
      functionName: "prizeToClaim",
      args: [addressRef.current],
    };
    setPrizeToClaim(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const approveTimeSpendSponsor = async () => {
    setChosenToken("TIME");
    setApprovedTo("Sponsor");
    setApproveButtonClickedSponsor(true);
    setAction("approve");
    if (
      textFieldTimeValueSponsor == "" ||
      parseFloat(textFieldTimeValueSponsor.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setApproveButtonClickedSponsor(false);
    } else {
      const params = {
        address: TimeToken.chain[getNetwork()?.chain.id].address,
        abi: TimeToken.abi,
        functionName: "approve",
        args: [
          SponsorContract.chain[getNetwork()?.chain.id].address,
          ethers.utils.parseEther(
            parseFloat(textFieldTimeValueSponsor.split(",").join("")).toString()
          ),
        ],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_D2_ARBITRUM
              : GAS_LIMIT_D2,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas,
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasApproved(true);
            setApproveButtonClickedSponsor(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setApproveButtonClickedSponsor(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setApproveButtonClickedSponsor(false);
      }
    }
  };

  const extendParticipationPeriod = async () => {
    setExtendButtonClickedSponsor(true);
    setAction("deposit");
    if (
      textFieldTimeValueSponsor == "" ||
      parseFloat(textFieldTimeValueSponsor.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setExtendButtonClickedSponsor(false);
    } else {
      let amountTime = textFieldTimeValueSponsor.split(",").join("");
      if (
        parseFloat(amountTime) > parseFloat(balanceInTime.split(",").join(""))
      ) {
        setHasEnoughTimeDialog(true);
        setExtendButtonClickedSponsor(false);
        return;
      }

      if (parseFloat(amountTime) >= parseFloat(maxBalanceInTime.split(",").join("")))
        amountTime = maxBalanceInTime;
      // if (amountTime == parseFloat(balanceInTime.split(",").join("")))
      //   amountTime = maxBalanceInTime;

      const params = {
        address: SponsorContract.chain[getNetwork()?.chain.id].address,
        abi: SponsorContract.abi,
        functionName: "extendParticipationPeriod",
        args: [ethers.utils.parseEther(amountTime)],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas,
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasExtended(true);
            setExtendButtonClickedSponsor(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setExtendButtonClickedSponsor(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setExtendButtonClickedSponsor(false);
      }
    }
  };

  const claimPrize = async () => {
    setEnabledClaimPrizeButtonClicked(true);
    setClaimType("prize");
    if (getNetwork()?.chain) {
      const params = {
        address: SponsorContract.chain[getNetwork()?.chain.id].address,
        abi: SponsorContract.abi,
        functionName: "claimPrize",
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT,
        },
        account,
      };
      const gas = await publicClient.estimateContractGas(params);
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit: gas,
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setIsClaimSuccess(true);
            setEnabledClaimPrizeButtonClicked(false);
          })
          .catch((error) => {
            setErrorMessage(`Please check if you have any claimable prize`);
            setIsTxError(true);
            setEnabledClaimPrizeButtonClicked(false);
          });
      } catch (error) {
        setErrorMessage(error.message);
        setIsTxError(true);
        setEnabledClaimPrizeButtonClicked(false);
      }
    }
  };

  // WORKER SECTION
  const approveTupOnWorker = async () => {
    setChosenToken("TUP");
    setApprovedTo("Worker");
    setApproveButtonClickedWorker(true);
    setAction("approve");
    if (
      textFieldTupValueWorker == "" ||
      parseFloat(textFieldTupValueWorker.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setApproveButtonClickedWorker(false);
    } else {
      let amountTup = 0;
      if (
        parseFloat(textFieldTupValueWorker.split(",").join("")) ==
        parseFloat(maxBalanceInD2).toFixed(6)
      ) {
        amountTup = ethers.utils.parseEther(maxBalanceInD2);
      } else {
        amountTup = ethers.utils.parseEther(
          parseFloat(textFieldTupValueWorker.split(",").join("")).toString()
        );
      }
      const params = {
        address: D2Token.chain[getNetwork()?.chain.id].address,
        abi: D2Token.abi,
        functionName: "approve",
        args: [
          WorkerVault.chain[getNetwork()?.chain.id].address,
          amountTup,
        ],
        account,
      };
      // const gas = await publicClient.estimateContractGas(params);
      // const config = await prepareWriteContract({
      //   ...params,
      //   overrides: {
      //     gasLimit: gas
      //   },
      // });
      let gasLimit;
      publicClient.estimateContractGas(params)
        .then((response) => {
          gasLimit = response;
        })
        .catch((error) => {
          gasLimit = GAS_LIMIT;
        });
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasApproved(true);
            setApproveButtonClickedWorker(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setApproveButtonClickedWorker(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setApproveButtonClickedWorker(false);
      }
    }
  };

  const checkIfWithdrawIsEnabled = async () => {
    const config = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "isEmergencyWithdrawEnabled",
    };
    const result = await readContract(config);
    setIsWithdrawFromWorkerEnabled(result || (releaseBlock <= block && releaseBlock != 0));
  };

  const callToProduction = async () => {
    setCallToProductionButtonClicked(true);
    if (getNetwork()?.chain) {
      const params = {
        address: WorkerVault.chain[getNetwork()?.chain.id].address,
        abi: WorkerVault.abi,
        functionName: "callToProduction",
        args: [addressRef.current],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_D2_ARBITRUM
              : GAS_LIMIT_D2,
        },
        account,
      };
      // const gasLimit = await publicClient.estimateContractGas(params);
      // const config = await prepareWriteContract({
      //   ...params,
      //   overrides: {
      //     gasLimit,
      //   }
      // });
      let gasLimit;
      publicClient.estimateContractGas(params)
        .then((response) => {
          gasLimit = response;
        })
        .catch((error) => {
          gasLimit = GAS_LIMIT_D2;
        });
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setIsSuccess(true);
            setCallToProductionButtonClicked(false);
          })
          .catch((error) => {
            setErrorMessage(`Not able to produce TIME from Worker`);
            setIsTxError(true);
            setCallToProductionButtonClicked(false);
          });
      } catch (error) {
        setErrorMessage(error.message);
        setIsTxError(true);
        setCallToProductionButtonClicked(false);
      }
    }
  };

  const depositTupOnWorker = async () => {
    setDepositButtonClickedWorker(true);
    setAction("deposit");
    setApprovedTo("Worker");
    if (
      textFieldTupValueWorker == "" ||
      parseFloat(textFieldTupValueWorker.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setDepositButtonClickedWorker(false);
    } else {
      let amountTup = textFieldTupValueWorker.split(",").join("");
      if (
        parseFloat(amountTup) > parseFloat(balanceInD2.split(",").join(""))
      ) {
        setHasEnoughTupDialog(true);
        setDepositButtonClickedWorker(false);
        return;
      }

      if (parseFloat(amountTup) >= parseFloat(maxBalanceInD2.split(",").join("")).toFixed(6))
        amountTup = maxBalanceInD2;

      const params = {
        address: WorkerVault.chain[getNetwork()?.chain.id].address,
        abi: WorkerVault.abi,
        functionName: "deposit",
        args: [ethers.utils.parseEther(amountTup), addressRef.current],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      // const gasLimit = await publicClient.estimateContractGas(params);
      // const config = await prepareWriteContract({
      //   ...params,
      //   overrides: {
      //     gasLimit
      //   }
      // });
      let gasLimit;
      publicClient.estimateContractGas(params)
        .then((response) => {
          gasLimit = response;
        })
        .catch((error) => {
          gasLimit = GAS_LIMIT_ARBITRUM;
        });
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasDeposited(true);
            setDepositButtonClickedWorker(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setDepositButtonClickedWorker(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setDepositButtonClickedWorker(false);
      }
    }
  };

  const disableButtons = async () => {
    setClaimButtonClicked(false);
    setClaimButtonMintD2Clicked(false);
    setSendFromD2ButtonClicked(false);
    setSwapButtonClicked(false);
    setTokenButtonClicked(false);
    setAnticipateEarningsButtonClicked(false);
    setApproveButtonClicked(false);
    setCompoundButtonClicked(false);
    setDepositButtonClicked(false);
    setEnabledAnticipationButtonClicked(false);
    setWithdrawDepositButtonClicked(false);
    setWithdrawEarningsButtonClicked(false);
    setApproveExchangeButtonClicked(false);
    setApproveMintD2ButtonClicked(false);
    setMintD2ButtonClicked(false);
    setApproveButtonClickedSponsor(false);
    setEnabledClaimPrizeButtonClicked(false);
    setExtendButtonClickedSponsor(false);
    setApproveButtonClickedWorker(false);
    setCallToProductionButtonClicked(false);
    setDepositButtonClickedWorker(false);
    setWithdrawButtonClickedWorker(false);
    setClaimFromWorkerButtonClicked(false);
  };

  // const queryConsumedShares = async () => {
  //   const config = {
  //     address: WorkerVault.chain[getNetwork()?.chain.id].address,
  //     abi: WorkerVault.abi,
  //     functionName: "consumedShares",
  //     args: [addressRef.current],
  //   };
  //   console.log(`Consumed Shares for ${addressRef.current}: ${convert(await readContract(config))}`);
  //   console.log(`Balance in Shares for ${addressRef.current}: ${balanceFromWorkerVault}`);
  // };

  const hasWorker = () => {
    return (
      getNetwork()?.chain?.id == fantom.id ||
      getNetwork()?.chain?.id == polygon.id ||
      getNetwork()?.chain?.id == arbitrum.id ||
      getNetwork()?.chain?.id == base.id ||
      getNetwork()?.chain?.id == optimism.id ||
      getNetwork()?.chain?.id == gnosis.id
      // getNetwork()?.chain?.id == sepolia.id ||
      // getNetwork()?.chain?.id == localhost.id
    );
  };

  const investWithNoCapital = async () => {
    setClaimFromWorkerButtonClicked(true);
    const params = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "investWithNoCapital",
      overrides: {
        gasLimit:
          getNetwork()?.chain.id == arbitrum.id
            ? GAS_LIMIT_ARBITRUM
            : GAS_LIMIT_EMPLOYER,
      },
      account,
    };
    // const gasLimit = await publicClient.estimateContractGas(params);
    // const config = await prepareWriteContract({
    //   ...params,
    //   overrides: {
    //     gasLimit
    //   }
    // });
    let gasLimit;
    publicClient.estimateContractGas(params)
      .then((response) => {
        gasLimit = response;
      })
      .catch((error) => {
        gasLimit = GAS_LIMIT_D2_ARBITRUM;
      });
    const config = await prepareWriteContract({
      ...params,
      overrides: {
        gasLimit
      }
    });
    try {
      const data = await writeContract(config);
      const transaction = await fetchTransaction({ hash: data.hash });
      const clTimeout = setTimeout(update, TIMEOUT);
      transaction
        ?.wait()
        .then((result) => {
          update();
          clearTimeout(clTimeout);
          setIsClaimFromWorkerSuccess(true);
          setClaimFromWorkerButtonClicked(false);
        })
        .catch((error) => {
          update();
          setErrorMessage(STANDARD_ERROR_MESSAGE);
          setIsTxError(true);
          setClaimFromWorkerButtonClicked(false);
        });
    } catch (error) {
      update();
      setErrorMessage(STANDARD_ERROR_MESSAGE);
      setIsTxError(true);
      setClaimFromWorkerButtonClicked(false);
    }
  };

  const queryAvailableTimeFromWorker = async () => {
    const config = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "queryAvailableTimeForDepositant",
      args: [addressRef.current],
    };
    setAvailableTimeFromWorker(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryBalanceFromWorkerVault = async () => {
    const config = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "balanceOf",
      args: [addressRef.current],
    };
    setBalanceFromWorkerVault(
      convert(await readContract(config))
      // parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
      //   minimumFractionDigits: 6,
      // })
    );
  };

  const queryBlockToUnlock = async () => {
    const config = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "blockToUnlock",
      args: [addressRef.current],
    };
    setReleaseBlock(parseInt(await readContract(config)));
  };

  const queryIsTupApprovedWorker = async (tupAmount) => {
    if (
      tupAmount ==
      parseFloat(maxBalanceInD2).toFixed(6)
    ) {
      tupAmount = ethers.utils.parseEther(maxBalanceInD2);
    } else {
      if (tupAmount != "") {
        tupAmount = ethers.utils.parseEther(tupAmount.toString());
      }
    }
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "allowance",
      args: [
        addressRef.current,
        WorkerVault.chain[getNetwork()?.chain.id].address,
      ],
    };
    if (tupAmount != "") {
      const allowance = await readContract(config);
      setIsApprovedToWorker(
        // allowance.gte(ethers.utils.parseEther(tupAmount.toString()))
        allowance.gte(tupAmount)
      );
    }
  };

  const queryNumberOfActiveMinions = async () => {
    const config = {
      address: MinionCoordinator.chain[getNetwork()?.chain.id].address,
      abi: MinionCoordinator.abi,
      functionName: "activeMinions",
    };
    setNumberOfMinions(parseInt(await readContract(config)));
  };

  const queryTotalAvailableTimeFromWorker = async () => {
    const config = {
      address: TimeToken.chain[getNetwork()?.chain.id].address,
      abi: TimeToken.abi,
      functionName: "balanceOf",
      args: [WorkerVault.chain[getNetwork()?.chain.id].address],
    };
    setTotalAvailableTimeFromWorker(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryTotalTupFromWorker = async () => {
    const config = {
      address: D2Token.chain[getNetwork()?.chain.id].address,
      abi: D2Token.abi,
      functionName: "balanceOf",
      args: [WorkerVault.chain[getNetwork()?.chain.id].address],
    };
    setTotalTupFromWorker(
      parseFloat(convert(await readContract(config))).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const queryTupLockedInWorkerVault = async () => {
    const config = {
      address: WorkerVault.chain[getNetwork()?.chain.id].address,
      abi: WorkerVault.abi,
      functionName: "convertToAssets",
      args: [ethers.utils.parseEther(balanceFromWorkerVault)],
    };
    const value = convert(await readContract(config));
    setMaxTupLockedInWorker(value);
    setTupLockedInWorker(
      parseFloat(value).toLocaleString("en-us", {
        minimumFractionDigits: 6,
      })
    );
  };

  const withdrawTupFromWorker = async () => {
    setWithdrawButtonClickedWorker(true);
    setAction("withdraw");
    setWithdrawnMethod("TUP");
    if (
      textFieldTupValueWorker == "" ||
      parseFloat(textFieldTupValueWorker.split(",").join("")) <= 0
    ) {
      setInformEmployerActionAmount(true);
      setWithdrawButtonClickedWorker(false);
    } else {
      let amountTup = textFieldTupValueWorker.split(",").join("");
      if (
        parseFloat(amountTup) > parseFloat(tupLockedInWorker.split(",").join(""))
      ) {
        setHasEnoughTupDialog(true);
        setWithdrawButtonClickedWorker(false);
        return;
      }

      let total = false;
      if (parseFloat(amountTup) >= parseFloat(maxTupLockedInWorker.split(",").join("")).toFixed(6)) {
        total = true;
      }

      const params = {
        address: WorkerVault.chain[getNetwork()?.chain.id].address,
        abi: WorkerVault.abi,
        functionName: total ? "redeem" : "withdraw",
        args: [total ? ethers.utils.parseEther(balanceFromWorkerVault) : ethers.utils.parseEther(amountTup), addressRef.current, addressRef.current],
        overrides: {
          gasLimit:
            getNetwork()?.chain.id == arbitrum.id
              ? GAS_LIMIT_ARBITRUM
              : GAS_LIMIT_EMPLOYER,
        },
        account,
      };
      // const gasLimit = await publicClient.estimateContractGas(params);
      // const config = await prepareWriteContract({
      //   ...params,
      //   overrides: {
      //     gasLimit
      //   }
      // });
      let gasLimit;
      publicClient.estimateContractGas(params)
        .then((response) => {
          gasLimit = response;
        })
        .catch((error) => {
          gasLimit = GAS_LIMIT_D2_ARBITRUM;
        });
      const config = await prepareWriteContract({
        ...params,
        overrides: {
          gasLimit
        }
      });
      try {
        const data = await writeContract(config);
        const transaction = await fetchTransaction({ hash: data.hash });
        const clTimeout = setTimeout(update, TIMEOUT);
        transaction
          ?.wait()
          .then((result) => {
            update();
            clearTimeout(clTimeout);
            setHasWithdrawn(true);
            setWithdrawButtonClickedWorker(false);
          })
          .catch((error) => {
            update();
            setErrorMessage(STANDARD_ERROR_MESSAGE);
            setIsTxError(true);
            setWithdrawButtonClickedWorker(false);
          });
      } catch (error) {
        update();
        setErrorMessage(STANDARD_ERROR_MESSAGE);
        setIsTxError(true);
        setWithdrawButtonClickedWorker(false);
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider
            modalSize="compact"
            chains={chains}
            key={`rainbowkit-${resetDate}`}
          >
            <Grid container alignItems="flex-start" height="100vh">
              <Grid
                xs={12}
                marginTop={2}
                marginBottom={2}
                container
                justifyContent="center"
                alignItems="center"
                justify="center"
              >
                <ConnectButton showBalance={false} />
              </Grid>
              <Grid xs={12}>
                <Divider />
              </Grid>
              <Grid
                xs={12}
                sx={{ justifyContent: { xs: "center" } }}
                container
                paddingTop={1.5}
                paddingBottom={1.5}
              >
                <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                  <Stack direction="row" justifyContent="center">
                    <LazyLoadImage
                      src={timeLogo}
                      alt="TIME Token Logo"
                      height={100}
                      width={100}
                    />
                    <Typography variant="h1" align="center" marginLeft={2}>
                      TIME Token
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ display: { sm: "none", xs: "grid" } }}>
                  <Grid
                    xs={12}
                    sx={{ justifyContent: { xs: "center", sm: "center" } }}
                    container
                  >
                    <LazyLoadImage
                      src={timeLogo}
                      alt="TIME Token Logo"
                      height={100}
                      width={100}
                    />
                  </Grid>
                  <Grid xs={12} justifyContent="center" container>
                    <Typography variant="h1" align="center">
                      TIME Token
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="center"
                width="100%"
              >
                <Info
                  key="info"
                  arbitrumLogo={arbitrumLogo}
                  avalancheLogo={avalancheLogo}
                  bscLogo={bscLogo}
                  celoLogo={celoLogo}
                  d2Logo={dsrv2Logo}
                  d2Price={d2Price}
                  dexBalance={dexBalance}
                  dexD2Balance={dexD2Balance}
                  dexBalanceInD2={dexBalanceInD2}
                  display={addressRef.current ? "block" : "none"}
                  displayD2={addressRef.current ? null : "none"}
                  displayExternal={
                    addressRef.current &&
                      (getNetwork()?.chain?.id == optimism.id ||
                        getNetwork()?.chain?.id == avalanche.id)
                      ? "none"
                      : null
                  }
                  ethereumLogo={ethereumLogo}
                  externalDexBalance={externalDexBalance}
                  externalDexD2Balance={externalDexD2Balance}
                  evmosLogo={evmosLogo}
                  fantomLogo={fantomLogo}
                  gnosisLogo={gnosisLogo}
                  moonriverLogo={moonriverLogo}
                  nativePrice={nativePrice}
                  networkChainId={network?.chain?.id}
                  polygonLogo={polygonLogo}
                  scrollLogo={scrollLogo}
                  updateInformationBox={updateInformationBox}
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  timeLogo={timeLogo}
                  timePrice={timePrice}
                  zkEVMLogo={zkEVMLogo}
                />
                <Production
                  key="production"
                  display={addressRef.current ? "block" : "none"}
                  address={addressRef.current}
                  balance={balance}
                  balanceInTime={balanceInTime}
                  block={block}
                  callToProduction={callToProduction}
                  callToProductionButtonClicked={callToProductionButtonClicked}
                  enable={enable}
                  enabled={enabled}
                  handlerSwitchEnableTime={handlerSwitchEnableTime}
                  hasWorker={hasWorker()}
                  mining={mining}
                  timeLogo={timeLogo}
                  fee={fee}
                  feeInTime={feeInTime}
                  setCallToProductionButtonClicked={setCallToProductionButtonClicked}
                  setTokenButtonClicked={setTokenButtonClicked}
                  symbol={symbol}
                  logo={logo}
                  logoAlt={logoAlt}
                  tabSize={TAB_SIZE}
                  totalSupply={totalSupply}
                  tokenButtonClicked={tokenButtonClicked}
                  updateProductionBox={updateProductionBox}
                  useTimeToken={useTimeToken}
                />
                <Swap
                  key="swap"
                  display={addressRef.current ? "block" : "none"}
                  addressFrom={addressFrom}
                  addressTo={addressTo}
                  approveExchangeButtonClicked={approveExchangeButtonClicked}
                  approveTokenTimeExchange={approveTokenTimeExchange}
                  balance={balance}
                  balanceInD2={balanceInD2}
                  balanceInTime={balanceInTime}
                  dexBalance={dexBalance}
                  dexBalanceTup={dexBalanceTup}
                  dexBalanceInD2={
                    dexBalanceInD2 === undefined
                      ? dexBalanceInD2
                      : parseFloat(dexBalanceInD2).toLocaleString("en-us", {
                        minimumFractionDigits: 6,
                      })
                  }
                  dexBalanceInTime={
                    dexBalanceInTime === undefined
                      ? dexBalanceInTime
                      : parseFloat(dexBalanceInTime).toLocaleString("en-us", {
                        minimumFractionDigits: 6,
                      })
                  }
                  enabledWarning={enabledWarning}
                  expectedAmount={expectedAmount}
                  flipped={flipped}
                  fromValue={fromValue}
                  isTokenApprovedTimeExchange={isTokenApprovedTimeExchange}
                  logo={logo}
                  logoAlt={logoAlt}
                  querySwapPrice={querySwapPrice}
                  queryIsTokenApprovedTimeExchange={
                    queryIsTokenApprovedTimeExchange
                  }
                  setAddressFrom={setAddressFrom}
                  setAddressTo={setAddressTo}
                  setFromValue={setFromValue}
                  setHasEnoughToExchangeDialog={setHasEnoughToExchangeDialog}
                  setTokenSymbolToSwap={setTokenSymbolToSwap}
                  textFieldValue={textFieldValue}
                  setTextFieldValue={setTextFieldValue}
                  textFieldTimeValueEnabled={textFieldTimeValueEnabled}
                  setTextFieldTimeValueEnabled={setTextFieldTimeValueEnabled}
                  textFieldEthValueEnabled={textFieldEthValueEnabled}
                  setConfirmSwap={setConfirmSwap}
                  setEnabledWarning={setEnabledWarning}
                  setTextFieldEthValueEnabled={setTextFieldEthValueEnabled}
                  setInformAmount={setInformAmount}
                  setFlipped={setFlipped}
                  setSwapButtonClicked={setSwapButtonClicked}
                  symbol={symbol}
                  swapButtonClicked={swapButtonClicked}
                  swap={swap}
                  tabSize={TAB_SIZE}
                  updateSwapBox={updateSwapBox}
                  timeLogo={timeLogo}
                  timeLogoAlt={"TIME Token Logo"}
                  dsrv2Logo={dsrv2Logo}
                  dsrv2LogoAlt={"TUP Token Logo"}
                  d2Address={
                    getNetwork()?.chain?.id
                      ? D2Token.chain[getNetwork()?.chain?.id].address
                      : null
                  }
                  timeAddress={
                    getNetwork()?.chain?.id
                      ? TimeToken.chain[getNetwork()?.chain?.id].address
                      : null
                  }
                  nativeAddress={ethers.constants.AddressZero}
                />
                <Sponsor
                  key="sponsor"
                  amountRemainingForPrize={amountRemainingForPrize}
                  approveButtonClickedSponsor={approveButtonClickedSponsor}
                  approveTimeSpendSponsor={approveTimeSpendSponsor}
                  balanceInTime={balanceInTime}
                  block={block}
                  claimPrize={claimPrize}
                  currentLeader={currentLeader}
                  currentPrize={currentPrize}
                  display={
                    addressRef.current &&
                      getNetwork()?.chain?.id != scroll.id &&
                      getNetwork()?.chain?.id != polygonZkEvm.id
                      ? "block"
                      : "none"
                  }
                  enabledClaimPrizeButtonClicked={
                    enabledClaimPrizeButtonClicked
                  }
                  extendButtonClickedSponsor={extendButtonClickedSponsor}
                  extendParticipationPeriod={extendParticipationPeriod}
                  interactionPointsLeader={interactionPointsLeader}
                  interactionPoints={interactionPoints}
                  isApprovedToSponsor={isApprovedToSponsor}
                  isParticipatingInSponsorship={isParticipatingInSponsorship}
                  logo={logo}
                  logoAlt={logoAlt}
                  numberOfParticipants={numberOfParticipants}
                  prizeToClaim={prizeToClaim}
                  querySponsorIsTimeApproved={querySponsorIsTimeApproved}
                  round={round}
                  setIsApprovedToSponsor={setIsApprovedToSponsor}
                  setTextFieldTimeValueSponsor={setTextFieldTimeValueSponsor}
                  sponsorLastBlock={sponsorLastBlock}
                  sponsorRemainingTime={sponsorRemainingTime}
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  textFieldTimeValueSponsor={textFieldTimeValueSponsor}
                  timeLogo={timeLogo}
                  updateSponsorBox={updateSponsorBox}
                  valuePoints={valuePoints}
                />
                <MintD2
                  key="mintd2"
                  approveMintD2ButtonClicked={approveMintD2ButtonClicked}
                  approveTimeSpendMintD2={approveTimeSpendMintD2}
                  availableTimeFromWorker={availableTimeFromWorker}
                  balance={balance}
                  balanceInD2={balanceInD2}
                  balanceInTime={balanceInTime}
                  claimButtonMintD2Clicked={claimButtonMintD2Clicked}
                  d2Logo={dsrv2Logo}
                  d2TotalSupply={d2TotalSupply}
                  display={addressRef.current ? "block" : "none"}
                  handlerSwitchEnableReinvestTup={handlerSwitchEnableReinvestTup}
                  handlerSwitchEnableTimeToMintD2={
                    handlerSwitchEnableTimeToMintD2
                  }
                  handlerSwitchEnableWorkerToMintTup={handlerSwitchEnableWorkerToMintTup}
                  hasWorker={hasWorker()}
                  isL2={isL2()}
                  isTimeApproveMintD2={isTimeApproveMintD2}
                  logo={logo}
                  logoAlt={logoAlt}
                  mintD2ButtonClicked={mintD2ButtonClicked}
                  publicReward={publicReward}
                  publicRewardTime={publicRewardTime}
                  queryAvailableTimeFromWorker={queryAvailableTimeFromWorker}
                  queryNativeFromTimeAmount={queryNativeFromTimeAmount}
                  queryIsTimeApprovedMintD2={queryIsTimeApprovedMintD2}
                  reinvestTup={reinvestTup}
                  setApproveMintD2ButtonClicked={
                    setApproveMintD2ButtonClicked
                  }
                  setClaimButtonMintD2Clicked={setClaimButtonMintD2Clicked}
                  setClaimType={setClaimType}
                  setConfirmClaim={setConfirmClaim}
                  setTextFieldEthValueMintD2={setTextFieldEthValueMintD2}
                  setTextFieldTimeValueMintD2={setTextFieldTimeValueMintD2}
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  textFieldEthValueMintD2={textFieldEthValueMintD2}
                  textFieldTimeValueMintD2={textFieldTimeValueMintD2}
                  timeLogo={timeLogo}
                  updateMintD2Box={updateMintD2Box}
                  useTimeTokenToMintD2={useTimeTokenToMintD2}
                  useWorkerToMintTup={useWorkerToMintTup}
                  willMintD2={willMintD2}
                />
                <Bridge
                  key="bridge"
                  arbitrumLogo={arbitrumLogo}
                  avalancheLogo={avalancheLogo}
                  balanceInD2={balanceInD2}
                  baseLogo={baseLogo}
                  bridgeError={bridgeError}
                  bscLogo={bscLogo}
                  celoLogo={celoLogo}
                  chainIdFrom={chainIdFrom}
                  chainIdTo={chainIdTo}
                  d2Logo={dsrv2Logo}
                  display={
                    addressRef.current && getNetwork()?.chain?.id != evmos.id
                      ?
                      "block"
                      : "none"
                  }
                  estimateBridgeD2Fee={estimateBridgeD2Fee}
                  estimatedBridgeD2Fee={estimatedBridgeD2Fee}
                  ethereumLogo={ethereumLogo}
                  evmosLogo={evmosLogo}
                  fantomLogo={fantomLogo}
                  feeProportion={feeProportion}
                  gnosisLogo={gnosisLogo}
                  moonriverLogo={moonriverLogo}
                  networkChainId={network?.chain?.id}
                  optimismLogo={optimismLogo}
                  performBridgeD2={performBridgeD2}
                  polygonLogo={polygonLogo}
                  scrollLogo={scrollLogo}
                  sendFromD2ButtonClicked={sendFromD2ButtonClicked}
                  setAction={setAction}
                  setBridgeError={setBridgeError}
                  setChainIdFrom={setChainIdFrom}
                  setChainIdTo={setChainIdTo}
                  setEstimatedBridgeD2Fee={setEstimatedBridgeD2Fee}
                  setFeeProportion={setFeeProportion}
                  setHasEnoughD2Dialog={setHasEnoughD2Dialog}
                  setInformChainId={setInformChainId}
                  setInformEmployerActionAmount={
                    setInformEmployerActionAmount
                  }
                  setTextFieldD2AmountBridge={setTextFieldD2AmountBridge}
                  setSendFromD2ButtonClicked={setSendFromD2ButtonClicked}
                  setSendFromDialog={setSendFromDialog}
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  textFieldD2AmountBridge={textFieldD2AmountBridge}
                  timeLogo={timeLogo}
                  updateBridgeBox={updateBridgeBox}
                  zkEVMLogo={zkEVMLogo}
                />
                <Employer
                  key="employer"
                  anticipate={anticipate}
                  anticipateTime={anticipateTime}
                  anticipationFee={anticipationFee}
                  apy={apy}
                  approveTimeSpend={approveTimeSpend}
                  balance={balance}
                  balanceInTime={balanceInTime}
                  block={block}
                  compound={compound}
                  deposit={deposit}
                  display={addressRef.current ? "block" : "none"}
                  enableAnticipation={enableAnticipation}
                  employerAnticipatedEarnings={employerAnticipatedEarnings}
                  employerEarnings={employerEarnings}
                  employerInvestment={employerInvestment}
                  employerLastBlock={employerLastBlock}
                  employerRemainingTime={employerRemainingTime}
                  handlerSwitchEnableTimeAnticipation={
                    handlerSwitchEnableTimeAnticipation
                  }
                  isAnticipationEnabled={isAnticipationEnabled}
                  isApproved={isApproved}
                  logo={logo}
                  logoAlt={logoAlt}
                  queryEmployerAnticipatedEarnings={
                    queryEmployerAnticipatedEarnings
                  }
                  queryIsTimeApproved={queryIsTimeApproved}
                  setAnticipateTime={setAnticipateTime}
                  setConfirmCompound={setConfirmCompound}
                  setTextFieldEthValueEmployer={setTextFieldEthValueEmployer}
                  setTextFieldTimeValueEmployer={
                    setTextFieldTimeValueEmployer
                  }
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  textFieldTimeValueEmployer={textFieldTimeValueEmployer}
                  textFieldEthValueEmployer={textFieldEthValueEmployer}
                  timeLogo={timeLogo}
                  totalEthDeposited={totalEthDeposited}
                  totalTimeEmployed={totalTimeEmployed}
                  withdrawDeposit={withdrawDeposit}
                  withdrawEarnings={withdrawEarnings}
                  anticipateEarningsButtonClicked={
                    anticipateEarningsButtonClicked
                  }
                  approveButtonClicked={approveButtonClicked}
                  compoundButtonClicked={compoundButtonClicked}
                  depositButtonClicked={depositButtonClicked}
                  enabledAnticipationButtonClicked={
                    enabledAnticipationButtonClicked
                  }
                  updateEmployerBox={updateEmployerBox}
                  withdrawDepositButtonClicked={withdrawDepositButtonClicked}
                  withdrawEarningsButtonClicked={
                    withdrawEarningsButtonClicked
                  }
                />
                <Worker
                  key="worker"
                  approveButtonClickedWorker={approveButtonClickedWorker}
                  approveTupOnWorker={approveTupOnWorker}
                  availableTimeFromWorker={availableTimeFromWorker}
                  balanceInD2={balanceInD2}
                  block={block}
                  d2Logo={dsrv2Logo}
                  depositButtonClickedWorker={depositButtonClickedWorker}
                  depositTupOnWorker={depositTupOnWorker}
                  display={addressRef.current && hasWorker() ? "block" : "none"}
                  isApprovedToWorker={isApprovedToWorker}
                  isWithdrawFromWorkerEnabled={isWithdrawFromWorkerEnabled}
                  numberOfMinions={numberOfMinions}
                  queryIsTupApprovedWorker={queryIsTupApprovedWorker}
                  releaseBlock={releaseBlock}
                  setApproveButtonClickedWorker={setApproveButtonClickedWorker}
                  setIsApprovedToWorker={setIsApprovedToWorker}
                  setTextFieldTupValueWorker={setTextFieldTupValueWorker}
                  tabSize={TAB_SIZE}
                  timeLogo={timeLogo}
                  textFieldTupValueWorker={textFieldTupValueWorker}
                  totalAvailableTimeFromWorker={totalAvailableTimeFromWorker}
                  totalTupFromWorker={totalTupFromWorker}
                  tupLockedInWorker={tupLockedInWorker}
                  updateWorkerBox={updateWorkerBox}
                  withdrawButtonClickedWorker={withdrawButtonClickedWorker}
                  withdrawTupFromWorker={withdrawTupFromWorker}
                />
                <CommunityPool
                  key="community_pool"
                  display={addressRef.current ? "block" : "none"}
                  balanceInTime={balanceInTime}
                  claimButtonClicked={claimButtonClicked}
                  claimFromWorkerButtonClicked={claimFromWorkerButtonClicked}
                  hasWorker={hasWorker()}
                  investWithNoCapital={investWithNoCapital}
                  logo={logo}
                  logoAlt={logoAlt}
                  setClaimButtonClicked={setClaimButtonClicked}
                  setClaimFromWorkerButtonClicked={setClaimFromWorkerButtonClicked}
                  setClaimType={setClaimType}
                  setConfirmClaim={setConfirmClaim}
                  sharedBalance={sharedBalance}
                  symbol={symbol}
                  tabSize={TAB_SIZE}
                  totalSupply={totalSupply}
                  updateCommunityPoolBox={updateCommunityPoolBox}
                  withdrawableShare={withdrawableShare}
                />
                {/* <SwipeLux
                  key="on_ramp"
                  display={addressRef.current ? "block" : "none"}
                  tabSize={TAB_SIZE}
                /> */}
              </Grid>
              <BottomNavigation
                showLabels
                sx={{
                  width: "100%",
                  backgroundColor: "#2a7f9e",
                  marginTop: "auto",
                  height: { xs: "5em", sm: "4em" },
                }}
              >
                <BottomNavigationAction
                  label="Paper"
                  icon={<ArticleIcon />}
                  href="https://bafybeifpuveptuflcg4hejdgx24mm4j2x26bk7sdsrhnbeuzwucjb754du.ipfs.w3s.link/paper_updated.pdf"
                  sx={{
                    fontSizeAdjust: { xs: "0.42", sm: "0.5", md: "0.55" },
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                />
                <BottomNavigationAction
                  label="Twitter Page"
                  icon={<TwitterIcon />}
                  component={Link}
                  href="https://twitter.com/TIMETokenT"
                  sx={{
                    fontSizeAdjust: { xs: "0.42", sm: "0.5", md: "0.55" },
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                />
                <BottomNavigationAction
                  label="Telegram Group"
                  icon={<TelegramIcon />}
                  component={Link}
                  href="https://t.me/timetoken0"
                  sx={{
                    fontSizeAdjust: { xs: "0.42", sm: "0.5", md: "0.55" },
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                />
                <BottomNavigationAction
                  label="GitBook"
                  icon={<GitBookIcon />}
                  component={Link}
                  href="https://docs.timetoken.finance"
                  sx={{
                    fontSizeAdjust: { xs: "0.42", sm: "0.5", md: "0.55" },
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                />
              </BottomNavigation>
            </Grid>

            <Dialog
              open={confirmSwap}
              onClose={() => {
                setConfirmSwap(false);
                setSwapButtonClicked(false);
              }}
            >
              <DialogTitle>Important Notice!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You confirm to swap {fromValue} {tokenSymbolToSwap} and
                  understand that the executed amount may not exactly match the
                  calculated amount.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setConfirmSwap(false);
                    setSwapButtonClicked(false);
                  }}
                >
                  DO NOT CONFIRM
                </Button>
                <Button
                  onClick={() => {
                    setSwapButtonClicked(true);
                    swap(fromValue.split(",").join(""));
                    setConfirmSwap(false);
                  }}
                >
                  CONFIRM
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={confirmClaim}
              onClose={() => {
                setConfirmClaim(false);
              }}
            >
              <DialogTitle>Please Confirm</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Would you like to claim{" "}
                  {claimType == "reward_d2" ? publicReward : withdrawableShare}{" "}
                  {claimType == "reward_d2" && !isL2()
                    ? `TUP and ${publicRewardTime} TIME (estimated)`
                    : symbol}
                  ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setConfirmClaim(false);
                  }}
                >
                  NO
                </Button>
                <Button
                  onClick={() => {
                    switch (claimType) {
                      case "community_pool":
                        claimShare();
                        break;
                      case "reward_d2":
                        claimReward();
                        break;
                      default:
                        break;
                    }
                    setConfirmClaim(false);
                  }}
                >
                  YES
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={confirmCompound}
              onClose={() => {
                setConfirmCompound(false);
                setCompoundButtonClicked(false);
              }}
            >
              <DialogTitle>Please Confirm</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Would you like to compound{" "}
                  {anticipateTime
                    ? employerAnticipatedEarnings
                      ? employerAnticipatedEarnings
                      : employerEarnings
                    : employerEarnings}{" "}
                  {symbol}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setConfirmCompound(false);
                    setClaimButtonClicked(false);
                  }}
                >
                  NO
                </Button>
                <Button
                  onClick={() => {
                    compound();
                    setConfirmCompound(false);
                  }}
                >
                  YES
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughTimeMiningDialog}
              onClose={() => {
                setHasEnoughTimeMiningDialog(false);
                setTokenButtonClicked(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough balance to enable mining by using TIME
                  tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughTimeMiningDialog(false);
                    setTokenButtonClicked(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughTimeDialog}
              onClose={() => {
                setHasEnoughTimeDialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough TIME tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughTimeDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughTupDialog}
              onClose={() => {
                setHasEnoughTupDialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough TUP tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughTupDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughTimeApprovedDialog}
              onClose={() => {
                setHasEnoughTimeApprovedDialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough TIME tokens approved to spend.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughTimeApprovedDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughBalanceDialog}
              onClose={() => {
                setHasEnoughBalanceDialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough balance.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughBalanceDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughD2Dialog}
              onClose={() => {
                setHasEnoughD2Dialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough TUP tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughD2Dialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasTimeNativeValueDialog}
              onClose={() => {
                setMintD2ButtonClicked(false);
                setHasTimeNativeValueDialog(false);
              }}
            >
              <DialogTitle>Warning!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You must send the same amount or more in {symbol} than what
                  your TIME tokens are worth. Your informed TIME current values{" "}
                  {minNativeAmountToMintD2} {symbol}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setMintD2ButtonClicked(false);
                    setHasTimeNativeValueDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={informAmount}
              onClose={() => {
                setInformAmount(false);
                setSwapButtonClicked(false);
              }}
            >
              <DialogTitle>Attention!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please inform the amount you want to swap.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setInformAmount(false);
                    setSwapButtonClicked(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={informChainId}
              onClose={() => {
                setInformChainId(false);
              }}
            >
              <DialogTitle>Attention!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please inform the network you want to bridge.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setInformChainId(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={informEmployerActionAmount}
              onClose={() => {
                setInformEmployerActionAmount(false);
              }}
            >
              <DialogTitle>Attention!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please inform the amount you want to {action}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setInformEmployerActionAmount(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={hasApproved} onClose={() => setHasApproved(false)}>
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your have successfully approved {approvedTo} to spend your{" "}
                  {chosenToken} tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasApproved(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={isSuccess} onClose={() => setIsSuccess(false)}>
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully generated TIME tokens! Please check your
                  available balance.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsSuccess(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={isSwapSuccess} onClose={() => setIsSuccess(false)}>
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your swap is complete! Please check your updated balance.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsSwapSuccess(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isClaimSuccess}
              onClose={() => setIsClaimSuccess(false)}
            >
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your have claimed your{" "}
                  {claimType == "reward_d2"
                    ? "reward"
                    : claimType == "prize"
                      ? claimType
                      : "share"}{" "}
                  successfully! Please check your updated balance.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsClaimSuccess(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isClaimFromWorkerSuccess}
              onClose={() => setIsClaimSuccess(false)}
            >
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your have claimed from Worker successfully! Please check your update balance in locked TUP tokens.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsClaimFromWorkerSuccess(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isMintD2Success}
              onClose={() => setIsMintD2Success(false)}
            >
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your have successfully minted TUP tokens! Please check your
                  updated balance.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsMintD2Success(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={enabledMessage}
              onClose={() => setEnabledMessage(false)}
            >
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully enabled your address to produce TIME
                  tokens!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEnabledMessage(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={enabledAnticipationMessage}
              onClose={() => setEnabledAnticipationMessage(false)}
            >
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully enabled your address to anticipate TIME
                  tokens on Employer!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEnabledAnticipationMessage(false)}>
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={hasDeposited} onClose={() => setHasDeposited(false)}>
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully deposited into the {approvedTo}!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasDeposited(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={hasExtended} onClose={() => setHasExtended(false)}>
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully extended your Sponsorship time!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasExtended(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasCompounded}
              onClose={() => setHasCompounded(false)}
            >
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully compounded your earnings!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasCompounded(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasAnticipated}
              onClose={() => setHasAnticipated(false)}
            >
              <DialogTitle>Congratulations!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully anticipated your earnings!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasAnticipated(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={hasWithdrawn} onClose={() => setHasWithdrawn(false)}>
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully withdrawn your {withdrawnMethod}!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHasWithdrawn(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={hasEnoughToExchangeDialog}
              onClose={() => {
                setHasEnoughToExchangeDialog(false);
                setSwapButtonClicked(false);
              }}
            >
              <DialogTitle>Error!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You don't have enough {tokenSymbolToSwap} to exchange.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setHasEnoughToExchangeDialog(false);
                    setSwapButtonClicked(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={isTxError} onClose={() => setIsTxError(false)}>
              <DialogTitle>Error!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  There was an error with your transaction. {errorMessage}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsTxError(false)}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={sendFromDialog}
              onClose={() => {
                setSendFromDialog(false);
              }}
            >
              <DialogTitle>Attention!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please note that the bridge function is performed by third
                  parties, and we are not responsible for any fund losses. We
                  recommend sending a small amount of resources initially and
                  only then sending more. Do you confirm the sending of{" "}
                  {textFieldD2AmountBridge} TUP tokens?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setSendFromDialog(false);
                    setSendFromD2ButtonClicked(false);
                  }}
                >
                  DO NOT CONFIRM
                </Button>
                <Button
                  onClick={() => {
                    setSendFromDialog(false);
                    setSendFromD2ButtonClicked(true);
                    performBridgeD2();
                  }}
                >
                  CONFIRM
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isBridgeD2Success}
              onClose={() => setIsBridgeD2Success(false)}
            >
              <DialogTitle>Succeeded!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have successfully transferred your TUP tokens over bridge!
                  Please follow your transaction:{" "}
                  <Link href={layerZeroScanLink}>{txHash}</Link>{" "}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsBridgeD2Success(false)}>OK</Button>
              </DialogActions>
            </Dialog>
          </RainbowKitProvider>
        </WagmiConfig>
      </ThemeProvider>
    </>
  );
};

export default App;
