import { StrictMode } from 'react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const STRICT_MODE = false;

const root = createRoot(document.getElementById('root'));

if (STRICT_MODE)
    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
else
    root.render(
        <App />
    );