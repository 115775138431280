import { SvgIcon } from "@mui/material";

const GitBookIcon = (props) => (
   <SvgIcon {...props}
  fill="#11333f"
  viewBox="0 0 24 17.292"
  role="img"
  version="1.1"
  id="svg133"
  sodipodidocname="gitbook.svg"
  width="24"
  height="17.292"
  inkscapeversion="1.1.2 (0a00cf5339, 2022-02-04)"
  xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape"
  xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  xmlns="http://www.w3.org/2000/svg"
  xmlnssvg="http://www.w3.org/2000/svg">
 <defs
    id="defs137" />
 <sodipodiNamedview
    id="namedview135"
    pagecolor="#ffffff"
    bordercolor="#666666"
    borderopacity="1.0"
    inkscapepageshadow="2"
    inkscapepageopacity="0.0"
    inkscapepagecheckerboard="0"
    showgrid="false"
    width="24px"
    inkscapezoom="12.75"
    inkscapecx="6.4313725"
    inkscapecy="13.72549"
    inkscapewindow-width="1366"
    inkscapewindow-height="719"
    inkscapewindow-x="0"
    inkscapewindow-y="25"
    inkscapewindow-maximized="1"
    inkscapecurrent-layer="svg133" />
 <path
    d="m 10.801808,14.415908 a 0.70300071,0.70300071 0 1 1 -0.002,1.406 0.70300071,0.70300071 0 0 1 0.002,-1.406 m 11.024,-4.347 a 0.70300018,0.70300018 0 1 1 0.001,-1.4059998 0.70300018,0.70300018 0 0 1 -0.001,1.4059998 m 0,-2.8759998 a 2.176,2.176 0 0 0 -2.174,2.174 c 0,0.233 0.039,0.465 0.115,0.6909998 l -7.181,3.823 a 2.165,2.165 0 0 0 -1.784,-0.937 c -0.8290003,0 -1.5840003,0.475 -1.9500003,1.216 l -6.451,-3.402 c -0.682,-0.358 -1.192,-1.4799998 -1.138,-2.5019998 0.028,-0.533 0.212,-0.947 0.493,-1.107 0.178,-0.1 0.392,-0.092 0.62,0.027 l 0.042,0.023 c 1.71,0.9 7.304,3.8469998 7.54,3.9559998 0.3630003,0.169 0.5650003,0.237 1.1850003,-0.057 l 11.564,-6.0139998 c 0.17,-0.064 0.368,-0.227 0.368,-0.474 0,-0.342 -0.354,-0.477 -0.355,-0.477 -0.658,-0.315 -1.669,-0.788 -2.655,-1.25 -2.108,-0.987 -4.497,-2.10500002 -5.546,-2.65500002 -0.906,-0.474 -1.635,-0.074 -1.765,0.006 l -0.252,0.125 C 7.7798077,2.6939082 1.4598077,5.8239082 1.0998077,6.0429082 c -0.643,0.392 -1.042,1.173 -1.094,2.142 -0.08,1.537 0.703,3.1399998 1.824,3.7269998 l 6.822,3.518 a 2.175,2.175 0 0 0 2.1500003,1.862 2.177,2.177 0 0 0 2.173,-2.14 l 7.514,-4.073 c 0.38,0.298 0.853,0.461 1.337,0.461 a 2.176,2.176 0 0 0 2.174,-2.1739998 2.176,2.176 0 0 0 -2.174,-2.174"
    id="path131" />
</SvgIcon>
);

export default GitBookIcon;